import React, { useState } from 'react';

import { Group, UnstyledButton, Text, createStyles, Space, Collapse, Box } from '@mantine/core';
import { IconCaretRight } from '@tabler/icons-react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { INavLink } from 'routes/types';

const useStyles = createStyles((theme, { isActive }: { isActive: boolean }) => ({
  wrapper: {
    display: 'block',
    width: '100%',
    padding: `${theme.spacing.xs} ${theme.spacing.md} ${theme.spacing.xs} ${theme.spacing.xs}`,
    fontSize: theme.fontSizes.md,
    background: isActive ? theme.colors.blue[0] : theme.white,
    borderLeft: `4px solid ${isActive ? theme.colors.kiloBlue[7] : 'transparent'}`,

    svg: {
      fill: isActive ? theme.colors.kiloBlue[7] : theme.black,
    },
    '&:hover': {
      backgroundColor: theme.colors.gray[0],
    },
  },

  link: {
    display: 'block',
    padding: `${theme.spacing.xs} ${theme.spacing.md} ${theme.spacing.xs} calc(${theme.spacing.lg} * 2)`,
    fontSize: theme.fontSizes.sm,
    background: isActive ? theme.colors.blue[0] : theme.white,
    borderLeft: `4px solid ${isActive ? theme.colors.kiloBlue[7] : 'transparent'}`,

    '&:hover': {
      backgroundColor: theme.colors.gray[0],
    },
  },
}));

interface INavSubLinkProps {
  key: string;
  label: string;
  route: string;
  isActive: boolean;
}

const NavSubLink = ({ label, route, isActive }: INavSubLinkProps): JSX.Element => {
  const { classes, theme } = useStyles({ isActive });

  const color = isActive ? theme.colors.kiloBlue[7] : theme.black;
  const weight = isActive ? 'bold' : 'normal';

  return (
    <Text weight={weight} className={classes.link} component={Link} to={route} color={color}>
      {label}
    </Text>
  );
};

const NavLink = ({ label, route, subLinks = [] }: INavLink): JSX.Element => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isActive = pathname === route;
  const { classes, theme } = useStyles({ isActive });
  const hasSubLinks: boolean = !!subLinks.length;
  const hasActiveSubLink: boolean = hasSubLinks && subLinks.map(item => item.route).includes(pathname);
  const [opened, setOpened] = useState(hasActiveSubLink);

  const handleClick = (): void => {
    if (hasSubLinks) {
      setOpened(!opened);
    } else {
      navigate(route);
    }
  };

  const color = isActive || hasActiveSubLink ? theme.colors.kiloBlue[7] : 'unset';
  const weight = isActive || hasActiveSubLink ? 'bold' : 'normal';

  return (
    <>
      <UnstyledButton type='button' className={classes.wrapper} onClick={handleClick}>
        <Group spacing='sm' sx={{ gap: `calc(${theme.spacing.xs} / 2)` }}>
          {hasSubLinks ? (
            <IconCaretRight
              size={theme.spacing.md}
              color={color}
              fill={color}
              style={{
                transform: opened ? 'rotate(90deg)' : 'unset',
                transition: 'ease-in 100ms',
                fill: color,
              }}
            />
          ) : (
            <Space w={theme.spacing.md} h={theme.spacing.md} />
          )}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              color,
              fontWeight: weight,
            }}
          >
            {label}
          </Box>
        </Group>
      </UnstyledButton>
      {hasSubLinks && (
        <Collapse in={opened}>
          {subLinks.map(link => (
            <NavSubLink
              key={link.key}
              label={link.label}
              route={link.route}
              isActive={link.route === pathname}
            />
          ))}
        </Collapse>
      )}
    </>
  );
};

export default NavLink;
