import React from 'react';

import { Select, SelectProps } from '@mantine/core';

export const COUNTRIES: Array<{ label: string; value: string }> = [
  { value: 'US', label: 'United States' },
  { value: 'CA', label: 'Canada' },
];

const CountryAutoComplete = ({ ...acProps }: Omit<SelectProps, 'data'>): JSX.Element => {
  return <Select data={COUNTRIES} placeholder='Select Country' label='Country' searchable {...acProps} />;
};

export default CountryAutoComplete;
