import { useCallback } from 'react';

import { AxiosError, AxiosResponse } from 'axios';
import { useClient } from 'context/auth-context';
import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { GoogleCalendarIntegration, GoogleCalendarIntegrationUpdate, ErrorMessage } from 'types';

export default function useUpdateGoogleCalendarIntegration(): UseMutationResult<
  AxiosResponse<GoogleCalendarIntegration>,
  AxiosError<ErrorMessage>,
  GoogleCalendarIntegrationUpdate
> {
  const client = useClient();
  const queryClient = useQueryClient();

  const updateGoogleCalendarIntegration = useCallback(
    (
      googleCalendarIntegration: GoogleCalendarIntegrationUpdate,
    ): Promise<AxiosResponse<GoogleCalendarIntegration>> => {
      return client.put(
        `google-calendar-integrations/${googleCalendarIntegration.googleCalendarIntegrationId}/`,
        googleCalendarIntegration,
      );
    },
    [client],
  );

  return useMutation(updateGoogleCalendarIntegration, {
    onSuccess: () => {
      queryClient.invalidateQueries('googleCalendarIntegrations');
    },
  });
}
