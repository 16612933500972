import React from 'react';

import { Divider, Stack } from '@mantine/core';

interface IInfoCardSectionProps {
  content: JSX.Element;
}

const InfoCardSection = ({ content }: IInfoCardSectionProps): JSX.Element => {
  return (
    <>
      <Divider my={0} />
      <Stack spacing='sm' px='md'>
        {content}
      </Stack>
    </>
  );
};

export default InfoCardSection;
