import { useClient } from 'context/auth-context';
import { useQuery, UseQueryResult } from 'react-query';
import { AthleteHasPastDueBills } from 'types';

export default function useAthleteHasPastDueBills(
  userId: string | undefined,
): UseQueryResult<{ data: AthleteHasPastDueBills }> {
  const client = useClient();

  return useQuery(
    ['athleteHasPastDueBills', userId],
    () => client.get(`athletes/${userId}/has-past-due-bills/`),
    {
      enabled: !!userId,
    },
  );
}
