import React, { useEffect, useState } from 'react';

import { createStyles, Flex, SegmentedControl, Text } from '@mantine/core';
import { Icon as TypeIcon } from '@tabler/icons-react';
import { useNavigate, matchRoutes, useLocation } from 'react-router-dom';
import BorderSize from 'types/enums';

const useStyles = createStyles(theme => ({
  root: {
    backgroundColor: theme.white,
    boxShadow: theme.shadows.md,
    border: `${BorderSize.sm} solid ${theme.colors.gray[1]}`,

    '.mantine-SegmentedControl-control:not(:first-of-type)': {
      borderWidth: 0,
    },
  },
  label: {
    '&, &:hover': {
      '&[data-active]': {
        color: theme.white,
      },
    },
  },
}));

type NavbarProps = {
  linksData: { Icon: TypeIcon; label: string; route: string }[];
};

const Navbar = ({ linksData }: NavbarProps): JSX.Element | null => {
  const { classes } = useStyles();
  const location = useLocation();
  const [pathname, setPathname] = useState<string>('');
  const [links] = useState(() =>
    linksData.map(({ Icon, label, route }) => ({
      label: (
        <Flex direction='column' align='center' gap={2}>
          <Icon size='1.625rem' />
          <Text size='xs' weight={600}>
            {label}
          </Text>
        </Flex>
      ),
      value: route,
    })),
  );

  useEffect(() => {
    const matchedRoutes = matchRoutes(
      linksData.map(route => ({ path: route.route })),
      location,
    );
    if (Array.isArray(matchedRoutes)) {
      setPathname(matchedRoutes[0].route.path);
    }
  }, [location, linksData]);
  const navigate = useNavigate();

  const handleChange = (value: string): void => {
    navigate(value);
  };

  return (
    <div style={{ width: '100%', position: 'fixed', bottom: 0, background: 'white' }}>
      <SegmentedControl
        value={pathname}
        color='kiloBlue'
        radius='lg'
        size='sm'
        mx='xs'
        mb='sm'
        data={links}
        classNames={classes}
        onChange={handleChange}
        fullWidth
      />
    </div>
  );
};

export default Navbar;
