import React from 'react';

import { Group, useMantineTheme } from '@mantine/core';
import { Dropzone, DropzoneProps, FileRejection, IMAGE_MIME_TYPE } from '@mantine/dropzone';
import { IconUpload, IconX } from '@tabler/icons-react';
import notificationHandler from 'utils/notifications';

interface IFileUploadProps extends DropzoneProps {
  handleFileReject?: (files: FileRejection[]) => void;
  accept?: string[];
  disabled?: boolean;
  src: JSX.Element;
}

const FileUpload = ({
  handleFileReject,
  accept = IMAGE_MIME_TYPE,
  disabled = false,
  src,
  ...dropzoneProps
}: IFileUploadProps): JSX.Element => {
  const { errorNotification } = notificationHandler();
  const theme = useMantineTheme();

  const handleReject = (files: FileRejection[]): void => {
    if (files[0].errors[0].code == 'file-too-large') {
      errorNotification('The selected file is too large. Please select a file smaller than 5MB.');
    } else {
      errorNotification(files[0].errors[0].message);
    }
  };

  return (
    <Dropzone
      onReject={handleFileReject || handleReject}
      maxSize={5 * 1024 ** 2}
      accept={accept}
      multiple={false}
      maxFiles={1}
      disabled={disabled}
      {...dropzoneProps}
    >
      <Group position='center' variant='xl' spacing='xl' style={{ pointerEvents: 'none' }}>
        <Dropzone.Accept>
          <IconUpload
            size='3.2rem'
            color={theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 4 : 6]}
            stroke={1.5}
          />
        </Dropzone.Accept>
        <Dropzone.Reject>
          <IconX size='3.2rem' stroke={1.5} color={theme.colors.red[theme.colorScheme === 'dark' ? 4 : 6]} />
        </Dropzone.Reject>
        <Dropzone.Idle>{src}</Dropzone.Idle>
      </Group>
    </Dropzone>
  );
};

export default FileUpload;
