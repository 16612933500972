import React from 'react';

import { subject } from '@casl/ability';
import { useAbility } from '@casl/react';
import { Group, Text, Title, UnstyledButton, createStyles, useMantineTheme } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconUserCircle } from '@tabler/icons-react';
import { AbilityContext } from 'Can';
import Menu from 'components/Menu';
import { useAuth } from 'context/auth-context';
import useImpersonation from 'hooks/kiloStaff/useImpersonate';
import { useNavigate } from 'react-router-dom';

import DrawerAccountDetails from './DrawerAccountDetails';

const useStyles = createStyles(theme => ({
  user: {
    display: 'block',
    width: '100%',
    padding: theme.spacing.md,
    color: theme.black,

    '&:hover': {
      backgroundColor: theme.colors.gray[0],
    },
  },
}));

const UserButton = (): JSX.Element => {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const { stopImpersonating, getImpersonatorToken } = useImpersonation();

  const navigate = useNavigate();
  const { user, logout } = useAuth();
  const ability = useAbility(AbilityContext);

  const [editAccountDetails, { open: openAccountdetails, close: closeAccountDetails }] =
    useDisclosure(false);

  const handleItemClick = (action: string): void => {
    if (action === 'Logout') {
      logout();
    } else if (action === 'Account') {
      openAccountdetails();
    } else if (action === 'Settings') {
      navigate('/gym-settings');
    } else if (action === 'stopImpersonating') {
      stopImpersonating();
    }
  };

  return (
    <>
      <Menu
        position='top'
        shadow='md'
        data-cy='userButton'
        target={
          <UnstyledButton className={classes.user}>
            <Group spacing='xs'>
              <IconUserCircle size={theme.fontSizes.xl} />
              <Text size='sm' weight={600}>
                {user?.fullName}
              </Text>
            </Group>
          </UnstyledButton>
        }
        items={[
          {
            title: 'Account Details',
            onClick: () => handleItemClick('Account'),
          },
          {
            title: 'Settings',
            onClick: () => handleItemClick('Settings'),
            display: ability.can(
              'read',
              subject('GymMutate', {
                isGymStaff: user.isGymStaff,
                isKiloStaff: user.isKiloStaff,
                isOwner: user.isOwner,
                permissions: user.permissions,
              }),
            )
              ? 'block'
              : 'none',
          },
          (getImpersonatorToken() !== undefined && {
            title: 'Stop Impersonating',
            onClick: () => handleItemClick('stopImpersonating'),
            color: 'red',
            divider: 'top',
          }) || {
            title: 'Logout',
            onClick: () => handleItemClick('Logout'),
            color: 'red',
            divider: 'top',
          },
        ]}
      />
      <DrawerAccountDetails
        opened={editAccountDetails}
        closeHandler={closeAccountDetails}
        title={<Title order={3}>Account Details</Title>}
        isGymStaff={user.isGymStaff}
      />
    </>
  );
};

export default UserButton;
