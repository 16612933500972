import React from 'react';

import { useMantineTheme, Loader, createStyles } from '@mantine/core';
import { IconSelector, IconX } from '@tabler/icons-react';

const useStyles = createStyles(() => ({
  button: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

interface IXButtonProps {
  isLoading: boolean;
  hasText: boolean;
  handleClick: () => void;
  handleFocus: () => void;
}

const SelectorButton = ({
  isLoading,
  hasText,
  handleClick,
  handleFocus,
}: IXButtonProps): JSX.Element | null => {
  const theme = useMantineTheme();
  const { classes } = useStyles();

  if (isLoading) {
    return <Loader size={theme.fontSizes.md} />;
  }

  if (hasText) {
    return <IconX size={theme.fontSizes.md} onClick={handleClick} className={classes.button} />;
  }

  return <IconSelector size={theme.fontSizes.md} onClick={() => handleFocus()} />;
};

export default SelectorButton;
