import { AxiosError, AxiosResponse } from 'axios';
import { useClient } from 'context/auth-context';
import { useMutation, useQueryClient, UseMutationResult } from 'react-query';
import { AthleteAppointment, AthleteAppointmentWrite, ErrorMessage } from 'types';

export default function useCreateAthleteAppointment(): UseMutationResult<
  AxiosResponse<AthleteAppointment>,
  AxiosError<ErrorMessage>,
  AthleteAppointmentWrite
> {
  const client = useClient();
  const queryClient = useQueryClient();

  const createAthleteAppointment = (
    athleteAppointment: AthleteAppointmentWrite,
  ): Promise<AxiosResponse<AthleteAppointment>> => {
    return client.post('athlete-appointments/', athleteAppointment);
  };

  return useMutation(createAthleteAppointment, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['athleteAppointments'],
      });
      queryClient.invalidateQueries({
        queryKey: ['athleteEvents'],
      });
      queryClient.invalidateQueries({
        queryKey: ['appointments'],
      });
      queryClient.invalidateQueries({
        queryKey: ['events'],
      });
      queryClient.invalidateQueries({
        queryKey: ['totalAttended'],
      });
      queryClient.invalidateQueries({
        queryKey: ['totalReserved'],
      });
    },
  });
}
