import { createContext } from 'react';

import { Ability, Subject, MongoQuery } from '@casl/ability';
import { createContextualCan } from '@casl/react';

export type PossibleAbilities = [string, Subject];
export type Conditions = MongoQuery;

export const AbilityContext = createContext({} as Ability<PossibleAbilities, Conditions>);
export const Can = createContextualCan(AbilityContext.Consumer);
