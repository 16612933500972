import React from 'react';

import { Button, Group, Modal, ModalProps, Text, Title } from '@mantine/core';

type EnterKioskModeModalProps = Pick<ModalProps, 'opened' | 'onClose'>;

const EnterKioskModeModal = ({ opened, onClose }: EnterKioskModeModalProps): JSX.Element => {
  const handleSwitchToKioskMode = (): void => {
    localStorage.setItem('isKiosk', 'true');
    window.location.reload();
  };

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title={<Title order={4}>Enter Kiosk mode</Title>}
      trapFocus
      centered
      size='auto'
      data-cy='enterKioskModeModal'
    >
      <Text>Are you sure that you want to switch to kiosk mode?</Text>
      <Group mt='md' position='right'>
        <Button variant='default' onClick={onClose} data-cy='cancelKioskModeBtn'>
          Cancel
        </Button>
        <Button onClick={handleSwitchToKioskMode} data-cy='enterKioskModeModalBtn'>
          Enter
        </Button>
      </Group>
    </Modal>
  );
};

export default EnterKioskModeModal;
