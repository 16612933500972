import React from 'react';

import { Collapse, Group, Title, useMantineTheme } from '@mantine/core';
import { IconCaretRight } from '@tabler/icons-react';

interface IExpandableSectionProps {
  title: string;
  opened: boolean;
  handleToggle: () => void;
  children: React.ReactNode;
  isDisabled?: boolean;
  dataCy?: string;
}

const ExpandableSection = ({
  title,
  opened,
  handleToggle,
  children,
  isDisabled,
  dataCy,
}: IExpandableSectionProps): JSX.Element => {
  const theme = useMantineTheme();
  const color = isDisabled ? theme.colors.gray[3] : 'black';
  return (
    <>
      <Group
        spacing='xs'
        onClick={isDisabled ? undefined : handleToggle}
        data-cy={dataCy ? dataCy : undefined}
      >
        <IconCaretRight
          size={theme.fontSizes.xl}
          color={color}
          fill={color}
          style={{
            transform: opened ? 'rotate(90deg)' : 'unset',
            transition: 'ease-in 100ms',
          }}
        />
        <Title order={4} weight={600} color={color}>
          {title}
        </Title>
      </Group>
      <Collapse in={opened}>{children}</Collapse>
    </>
  );
};

export default ExpandableSection;
