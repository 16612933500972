import KioskLayout from 'layouts/KioskLayout';
import Kiosk from 'pages/private/owner/Kiosk';
import { Navigate } from 'react-router-dom';

import { IRouteMap } from './types';

export const kioskRouteMap: IRouteMap = {
  layout: KioskLayout,
  routes: [
    {
      key: 'kiosk',
      component: () => Kiosk({ isKioskStaffRoute: true }),
      path: '/',
    },
    {
      key: 'redirect',
      component: () => Navigate({ to: '/' }),
      path: '*',
    },
  ],
  navLinks: [],
};

export const getKioskRouteMap = (): IRouteMap => {
  return kioskRouteMap;
};
