import React from 'react';

import { createStyles } from '@mantine/core';
import BannerModal from 'components/banner/BannerModal';
import useCurrentRoute from 'hooks/useCurrentRoute';
import Div100vh from 'react-div-100vh';
import { Outlet } from 'react-router-dom';

import Header from './components/Header';
import Navbar from './components/Navbar';

const useStyles = createStyles(theme => ({
  layout: {
    width: '100vw',
    display: 'flex',
    justifyContent: 'center',
    background: theme.colors.gray[2],

    '.page': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: '100%',
      maxWidth: theme.breakpoints.sm,
      position: 'relative',
      flexGrow: 1,
      background: theme.colors.gray[1],

      '.content': {
        padding: theme.spacing.md,
        overflowY: 'auto',
        maxWidth: theme.breakpoints.xs,
        width: '100%',
        flexGrow: 1,
      },
    },
  },
}));

const AthleteMobileLayout = (): JSX.Element => {
  const { classes } = useStyles();
  const pathname = useCurrentRoute();

  const isHeaderHidden = ['/'].includes(pathname);

  return (
    <Div100vh className={classes.layout}>
      <div className='page'>
        {!isHeaderHidden && <Header />}
        <Outlet />
        <Navbar />
        <BannerModal />
      </div>
    </Div100vh>
  );
};

export default AthleteMobileLayout;
