import { AxiosResponse } from 'axios';
import { useMutation, UseMutationResult } from 'react-query';
import { ValidateOTPSMSRequest } from 'types';
import { axiosApiServerInstance } from 'utils/api-client';

function useValidateSMSOTP(): UseMutationResult<AxiosResponse<null>, unknown, ValidateOTPSMSRequest> {
  return useMutation(request => axiosApiServerInstance.post('sales-portal/validate-sms-otp/', request));
}

export default useValidateSMSOTP;
