import React, { useEffect, useState } from 'react';

import { Button, Group, Modal, Stack, Title } from '@mantine/core';
import { RichTextEditor, Link } from '@mantine/tiptap';
import { TextAlign } from '@tiptap/extension-text-align';
import { Underline } from '@tiptap/extension-underline';
import { AnyExtension, useEditor } from '@tiptap/react';
import { StarterKit } from '@tiptap/starter-kit';
import useBanners from 'hooks/banner/useBanners';
import useCloseBanner from 'hooks/banner/useCloseBanner';

const BannerModal = ({}): JSX.Element => {
  const [isBannerModalOpen, setIsBannerModalOpen] = useState(false);
  const closeBannerMutation = useCloseBanner();

  const {
    data: bannerData,
    isLoading: bannerLoading,
    isError: bannerError,
    isFetching: bannerFetching,
  } = useBanners();

  const editor = useEditor({
    extensions: [
      StarterKit as AnyExtension,
      Underline,
      Link,
      TextAlign.configure({
        types: ['heading', 'paragraph'],
      }),
    ],
    content: '',
    editable: false,
  });

  const handleCloseBanner = (bannerId: string): void => {
    closeBannerMutation.mutate(bannerId);
    setIsBannerModalOpen(false);
  };

  useEffect(() => {
    if (!bannerLoading && !bannerFetching && bannerData?.data[0]) {
      editor?.commands.setContent(bannerData.data[0].message || '');
      setIsBannerModalOpen(true);
    } else if (bannerError) {
      editor?.commands.setContent('Something went wrong while fetching data!');
      setIsBannerModalOpen(true);
    } else {
      setIsBannerModalOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bannerData, bannerLoading, bannerFetching, bannerError]);

  return (
    <>
      <Modal
        opened={isBannerModalOpen}
        onClose={() => setIsBannerModalOpen(true)}
        title={<Title order={3}>{bannerData?.data[0]?.title || ''}</Title>}
        withCloseButton={false}
      >
        <Stack>
          <RichTextEditor editor={editor}>
            <RichTextEditor.Content />
          </RichTextEditor>
        </Stack>
        <Group position='right' sx={{ paddingTop: '10px' }}>
          <Button size='sm' onClick={() => handleCloseBanner(bannerData?.data[0].bannerId || '')}>
            Do Not Show Again
          </Button>
        </Group>
      </Modal>
    </>
  );
};

export default BannerModal;
