import { AxiosError, AxiosResponse } from 'axios';
import { useMutation, UseMutationResult } from 'react-query';
import { AccessToken, CustomTokenObtainPair, ErrorMessage } from 'types';
import * as authClient from 'utils/auth-client';

export default function useLogin(): UseMutationResult<
  AxiosResponse<AccessToken>,
  AxiosError<ErrorMessage>,
  CustomTokenObtainPair
> {
  return useMutation(authClient.login);
}
