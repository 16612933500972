import React from 'react';

import PrivateLayout from 'layouts/PrivateLayout';
import { Navigate } from 'react-router-dom';

import { IRouteMap } from './types';

export const kiloStaffRouteMap: IRouteMap = {
  layout: PrivateLayout,
  routes: [
    {
      key: 'gyms',
      component: React.lazy(() => import('pages/private/admin/Gyms')),
      path: '/gyms',
    },
    {
      key: 'gym-athletes',
      component: React.lazy(() => import('pages/private/admin/Athletes')),
      path: '/gyms/:gymId/athletes',
    },
    {
      key: 'gym-staff',
      component: React.lazy(() => import('pages/private/admin/Staff')),
      path: '/gyms/:gymId/staff',
    },
    {
      key: 'redirect',
      component: () => Navigate({ to: '/gyms' }),
      path: '*',
    },
  ],
  navLinks: [{ key: 'gyms', label: 'Gyms', route: '/gyms' }],
};

export const getKiloStaffRouteMap = (): IRouteMap => {
  return kiloStaffRouteMap;
};
