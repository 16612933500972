import axios, { AxiosResponse } from 'axios';
import config from 'config';
import { User, TokenVerify, AccessToken } from 'types';

export const axiosAuthInstance = axios.create({
  baseURL: config.AUTHENTICATION_API,
  withCredentials: true,
  xsrfCookieName: 'csrftoken',
  xsrfHeaderName: 'X-CSRFToken',
});

export function refreshToken(): Promise<AxiosResponse<AccessToken>> {
  return axiosAuthInstance.post('/jwt/refresh/');
}

export function verifyToken(payload: TokenVerify): Promise<AxiosResponse<void>> {
  return axiosAuthInstance.post('/jwt/verify/', payload);
}

export function login(payload: {
  email: string;
  password: string;
  rememberMe: boolean;
}): Promise<AxiosResponse<AccessToken>> {
  return axiosAuthInstance.post('/login/', payload);
}

export function logout(token: string | null): Promise<AxiosResponse<void>> {
  axiosAuthInstance.defaults.headers.common.Authorization = `JWT ${token}`;
  return axiosAuthInstance.post('/logout/', {
    headers: {
      Authorization: `JWT ${token}`,
    },
  });
}

export function loadMe(token: string | null): Promise<AxiosResponse<User>> {
  return axiosAuthInstance.get('/users/me/', {
    headers: {
      Authorization: `JWT ${token}`,
    },
  });
}
