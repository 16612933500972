import { useCallback } from 'react';

import { AxiosError, AxiosResponse } from 'axios';
import { useClient } from 'context/auth-context';
import { UseMutationResult, useMutation, useQueryClient } from 'react-query';

export default function useCloseBanner(): UseMutationResult<AxiosResponse<void>, AxiosError<void>, string> {
  const client = useClient();
  const queryClient = useQueryClient();

  const closeBanner = useCallback(
    (bannerId: string): Promise<AxiosResponse<void>> => {
      return client.get(`banners/${bannerId}/close/`);
    },
    [client],
  );

  return useMutation(closeBanner, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['banners'],
      });
    },
  });
}
