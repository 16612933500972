import { useCallback } from 'react';

import { AxiosResponse } from 'axios';
import { useClient } from 'context/auth-context';
import { useMutation, UseMutationResult } from 'react-query';
import { SetPasswordRetype } from 'types';

export default function useChangePassword(): UseMutationResult<
  AxiosResponse<void>,
  unknown,
  SetPasswordRetype
> {
  const client = useClient();

  const changePassword = useCallback(
    (req: SetPasswordRetype): Promise<AxiosResponse<any>> => {
      return client.post('/auth/users/set_password/', req);
    },
    [client],
  );
  return useMutation(changePassword);
}
