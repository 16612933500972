import React, { useState, useEffect, useRef } from 'react';

import { Autocomplete, AutocompleteProps, AutocompleteItem } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import useAutoCompleteAthlete from 'hooks/athlete/useAutoCompleteAthlete';
import { AthleteAutoComplete as AthleteAutoCompleteType } from 'types';

import SelectorButton from './SelectorButton';

export interface AthleteAutoCompleteValue extends AutocompleteItem {
  userId: string;
  label: string;
}

interface IAthleteAutoCompleteProps
  extends Omit<
    AutocompleteProps,
    'data' | 'value' | 'onChange' | 'filter' | 'onItemSubmit' | 'rightSection'
  > {
  value: AthleteAutoCompleteValue;
  onChange: (item: AthleteAutoCompleteValue | null) => void;
}

export interface IAthleteAutoComplete extends AthleteAutoCompleteType {
  value: string;
}

const AthleteAutoComplete = ({
  value,
  onChange,
  ...autocompleteProps
}: IAthleteAutoCompleteProps): JSX.Element => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [options, setOptions] = useState<AutocompleteItem[]>([]);
  const [search, setSearch] = useState(value.label);
  const [debouncedSearch] = useDebouncedValue(search, 250);

  const { data, isSuccess, isLoading } = useAutoCompleteAthlete({
    search: debouncedSearch,
  });

  const handleSearchChange = (query: string): void => {
    setSearch(query);
    onChange(null);
  };
  const handleItemSubmit = (item: AthleteAutoCompleteValue): void => {
    setSearch('');
    onChange(item);
  };
  const handleClearSearch = (): void => {
    setSearch('');
  };

  const RightSection = (
    <SelectorButton
      isLoading={isLoading}
      hasText={!!(value.label || search)}
      handleClick={() => handleSearchChange('')}
      handleFocus={() => inputRef?.current?.focus()}
    />
  );
  useEffect(() => {
    if (isSuccess && data) {
      setOptions(
        data.data.map((athlete: AthleteAutoCompleteType) => ({
          ...athlete,
          value: athlete.userId,
          label: athlete.fullName,
        })),
      );
    }
  }, [data, isSuccess]);

  return (
    <Autocomplete
      ref={inputRef}
      rightSection={RightSection}
      value={value.label || search}
      onChange={handleSearchChange}
      onItemSubmit={handleItemSubmit}
      onDropdownClose={handleClearSearch}
      filter={() => true}
      data={options}
      {...autocompleteProps}
    />
  );
};

export default AthleteAutoComplete;
