import React, { useMemo } from 'react';

import {
  Anchor,
  Badge,
  Button,
  createStyles,
  Divider,
  Flex,
  Group,
  Paper,
  Stack,
  Text,
  Title,
} from '@mantine/core';
import { IconClockFilled, IconMapPinFilled, IconUser, IconUsers } from '@tabler/icons-react';
import dayjs from 'dayjs';
import useCreateAthleteAppointment from 'hooks/athleteAppointment/useCreateAthleteAppointment';
import useDeleteAthleteAppointment from 'hooks/athleteAppointment/useDeleteAthleteAppointment';
import useUpdateAthleteAppointment from 'hooks/athleteAppointment/useUpdateAthleteAppointment';
import useCreateAthleteClass from 'hooks/athleteClass/useCreateAthleteClass';
import useDeleteAthleteClass from 'hooks/athleteClass/useDeleteAthleteClass';
import useUpdateAthleteClass from 'hooks/athleteClass/useUpdateAthleteClass';
import { AthleteAppointmentWrite, AthleteKiloClassWrite, Event } from 'types/generated';
import notificationHandler from 'utils/notifications';
import { v4 as uuidv4 } from 'uuid';

const useStyles = createStyles(theme => ({
  icon: {
    path: {
      fill: theme.colors.dark[2],
    },
  },
}));

interface EventCardProps extends Event {
  selectedAthleteName: string;
  selectedAthleteId: string;
}

const EventCard = ({
  selectedAthleteName,
  selectedAthleteId,
  eventType,
  eventName,
  eventId,
  startDate,
  startTime,
  duration,
  location,
  kiloClassCoachKiloClassCoachRoles,
  appointmentCoachAppointmentCoachRoles,
  capacity,
  athleteKiloClasses,
  athleteAppointments,
}: EventCardProps): JSX.Element => {
  const { classes, theme } = useStyles();

  const createAthleteClassMutation = useCreateAthleteClass();
  const updateAthleteClassMutation = useUpdateAthleteClass();
  const createAthleteAppointmentMutation = useCreateAthleteAppointment();
  const updateAthleteAppointmentMutation = useUpdateAthleteAppointment();
  const deleteAthleteClassMutation = useDeleteAthleteClass();
  const deleteAthleteAppointmentMutation = useDeleteAthleteAppointment();

  const fullStartDate = dayjs(`${startDate}T${startTime}`);
  const formattedStartTime = fullStartDate.format('h:mmA');
  const formattedEndTime = dayjs(fullStartDate).add(duration, 'minutes').format('h:mmA');

  const isAppointment = eventType === 'appointment_type';

  const selectedAthleteClass = athleteKiloClasses?.find(
    athleteKiloClass => athleteKiloClass.athlete.userId === selectedAthleteId,
  );
  const selectedAppointment = athleteAppointments?.find(
    athleteKiloClass => athleteKiloClass.athlete.userId === selectedAthleteId,
  );

  const isWaitlisted = isAppointment ? false : !!selectedAthleteClass?.isWaitlisted;
  const isReserved = isAppointment
    ? !!selectedAppointment?.isReserved && !selectedAppointment?.isCheckedIn
    : !!selectedAthleteClass?.isReserved && !selectedAthleteClass?.isCheckedIn;

  const isCheckedIn = !!selectedAthleteClass?.isCheckedIn || !!selectedAppointment?.isCheckedIn;
  const isCheckinButtonLoading =
    createAthleteClassMutation.isLoading ||
    updateAthleteClassMutation.isLoading ||
    createAthleteAppointmentMutation.isLoading ||
    updateAthleteAppointmentMutation.isLoading;
  const isCheckoutButtonLoading =
    deleteAthleteClassMutation.isLoading || deleteAthleteAppointmentMutation.isLoading;

  const athletes = isAppointment
    ? athleteAppointments
        ?.filter(appointment => appointment.isReserved || appointment.isCheckedIn)
        .map(athleteKiloClass => athleteKiloClass.athlete)
    : athleteKiloClasses
        ?.filter(kiloClass => kiloClass.isReserved || kiloClass.isCheckedIn)
        .map(athleteKiloClass => athleteKiloClass.athlete);
  const reservedAthletesCount = athletes?.length || 0;

  const isClassAtCapacity = capacity ? reservedAthletesCount >= capacity : false;

  const background = useMemo(() => {
    if (isCheckedIn) {
      return theme.fn.rgba(theme.colors.green[5], 0.1);
    }
    if (isReserved) {
      return theme.fn.rgba(theme.colors.kiloBlue[5], 0.1);
    }
    if (isWaitlisted) {
      return theme.fn.rgba(theme.colors.kiloBlue[5], 0.1);
    }
    return theme.white;
  }, [
    isCheckedIn,
    isReserved,
    isWaitlisted,
    theme.colors.green,
    theme.colors.kiloBlue,
    theme.fn,
    theme.white,
  ]);

  const { successNotification, errorNotification } = notificationHandler();

  const handleCheckInAthleteClass = (): void => {
    if (selectedAthleteId) {
      const payload: AthleteKiloClassWrite = {
        athleteKiloClassId: !!selectedAthleteClass ? selectedAthleteClass.athleteKiloClassId : uuidv4(),
        isReserved: !!selectedAthleteClass?.isReserved,
        isCheckedIn: true,
        isWaitlisted: false,
        kiloClass: eventId,
        athlete: selectedAthleteId,
        isLateCancel: false,
        isNoShow: false,
        chargeNow: null,
        chargeDate: null,
        chargeFee: null,
        taxRate: null,
        deductCredit: null,
        registrationDatetime: !!selectedAthleteClass
          ? selectedAthleteClass.registrationDatetime
          : dayjs(new Date()).format('YYYY-MM-DDTHH:mm:ss'),
      };

      if (!!selectedAthleteClass) {
        updateAthleteClassMutation.mutate(payload, {
          onSuccess: () => {
            successNotification(
              'Adding athlete to class success',
              `${selectedAthleteName} has been successfully added to ${eventName}.`,
            );
          },
          onError: error => {
            errorNotification('Adding athlete failed', error.response?.data.message);
          },
        });
      } else {
        createAthleteClassMutation.mutate(payload, {
          onSuccess: () => {
            successNotification(
              'Adding athlete to class success',
              `${selectedAthleteName} has been successfully added to ${eventName}.`,
            );
          },
          onError: error => {
            errorNotification('Adding athlete failed', error.response?.data.message);
          },
        });
      }
    }
  };
  const handleCheckInAthleteAppointment = (): void => {
    if (selectedAthleteId) {
      const payload: AthleteAppointmentWrite = {
        athleteAppointmentId: !!selectedAppointment ? selectedAppointment.athleteAppointmentId : uuidv4(),
        isReserved: !!selectedAppointment?.isReserved,
        isCheckedIn: true,
        appointment: eventId,
        athlete: selectedAthleteId,
        isLateCancel: false,
        isNoShow: false,
        chargeNow: null,
        chargeDate: null,
        chargeFee: null,
        taxRate: null,
        deductCredit: null,
      };
      if (!!selectedAppointment) {
        updateAthleteAppointmentMutation.mutate(payload, {
          onSuccess: () => {
            successNotification(
              'Creating appointment for athlete success',
              `${selectedAthleteName} has been successfully added to ${eventName}.`,
            );
          },
          onError: error => {
            errorNotification('Adding athlete failed', error.response?.data.message);
          },
        });
      } else {
        createAthleteAppointmentMutation.mutate(payload, {
          onSuccess: () => {
            successNotification(
              'Creating appointment for athlete success',
              `${selectedAthleteName} has been successfully added to ${eventName}.`,
            );
          },
          onError: error => {
            errorNotification('Adding athlete failed', error.response?.data.message);
          },
        });
      }
    }
  };

  const handleCheckoutAthleteClass = (): void => {
    if (selectedAthleteClass) {
      if (selectedAthleteClass.isReserved) {
        const payload: AthleteKiloClassWrite = {
          athleteKiloClassId: selectedAthleteClass.athleteKiloClassId,
          isReserved: selectedAthleteClass.isReserved,
          isCheckedIn: false,
          isWaitlisted: false,
          kiloClass: eventId,
          athlete: selectedAthleteId,
          registrationDatetime: selectedAthleteClass.registrationDatetime,
          isLateCancel: false,
          isNoShow: false,
          chargeNow: null,
          chargeDate: null,
          chargeFee: null,
          taxRate: null,
          deductCredit: null,
        };
        updateAthleteClassMutation.mutate(payload, {
          onSuccess: () => {
            successNotification('Athlete check out success');
          },
          onError: error => {
            errorNotification('Athlete check out failed', error.response?.data.message);
          },
        });
      } else {
        deleteAthleteClassMutation.mutate(selectedAthleteClass.athleteKiloClassId, {
          onSuccess: () => {
            successNotification('Athlete check out success');
          },
          onError: error => {
            errorNotification('Athlete check out failed', error.response?.data.message);
          },
        });
      }
    }
  };
  const handleCheckoutAthleteAppointment = (): void => {
    if (selectedAppointment) {
      deleteAthleteAppointmentMutation.mutate(selectedAppointment.athleteAppointmentId, {
        onSuccess: () => {
          successNotification('Athlete reservation cancel success', `Reservation successfully canceled.`);
        },
        onError: error => {
          errorNotification('Athlete reservation cancel failed', error.response?.data.message);
        },
      });
    }
  };

  const handleCheckin = (): void => {
    if (!isCheckedIn) {
      if (isAppointment) {
        handleCheckInAthleteAppointment();
      } else {
        handleCheckInAthleteClass();
      }
    }
  };

  const handleCheckOut = (): void => {
    if (!!selectedAthleteClass && !isAppointment) {
      handleCheckoutAthleteClass();
    } else if (!!selectedAppointment && isAppointment) {
      handleCheckoutAthleteAppointment();
    }
  };

  return (
    <Paper withBorder p='md' radius='md' bg={background} data-cy='eventCard'>
      <Flex wrap='nowrap' gap='md' align='center'>
        <Flex direction='column' justify='center' align='center' w='100%' maw={200} gap='xs'>
          <Title order={5} lineClamp={1}>
            {eventName}
          </Title>
          <Text align='center' size='sm' color='dark.4' weight={600}>
            {`${formattedStartTime} - ${formattedEndTime}`}
          </Text>
          {isCheckedIn && (
            <Badge color='green' variant='filled' size='sm' data-cy='reservationStatus'>
              Checked-in
            </Badge>
          )}
          {isReserved && (
            <Badge color='kiloBlue' variant='filled' size='sm' data-cy='reservationStatus'>
              Reserved
            </Badge>
          )}
          {isWaitlisted && (
            <Badge color='kiloBlue' variant='filled' size='sm' data-cy='reservationStatus'>
              In waitlist
            </Badge>
          )}
        </Flex>

        <Divider orientation='vertical' my='-xs' mr='xs' />
        <Group spacing='xs' noWrap align='top'>
          <Stack spacing='xs'>
            <Group spacing='xs' noWrap align='top'>
              <IconClockFilled size={theme.fontSizes.xl} className={classes.icon} />
              <Text color='dark.5' size='sm'>
                {`${duration} mins`}
              </Text>
            </Group>
            <Group spacing='xs' noWrap align='top'>
              <IconUser size={theme.fontSizes.xl} className={classes.icon} color={theme.colors.dark[2]} />
              {kiloClassCoachKiloClassCoachRoles.length && (
                <Stack sx={{ gap: 0 }}>
                  {kiloClassCoachKiloClassCoachRoles.map((ccccr, i) => (
                    <Text color={'dark.5'} size='sm' key={i}>
                      {ccccr.coach.fullName} ({ccccr.kiloClassCoachRole.name})
                    </Text>
                  ))}
                </Stack>
              )}
              {appointmentCoachAppointmentCoachRoles.length && (
                <Stack sx={{ gap: 0 }}>
                  {appointmentCoachAppointmentCoachRoles.map((acacr, i) => (
                    <Text color={'dark.5'} size='sm' key={i}>
                      {acacr.coach.fullName} ({acacr.appointmentCoachRole.name})
                    </Text>
                  ))}
                </Stack>
              )}

              {!appointmentCoachAppointmentCoachRoles.length &&
                !kiloClassCoachKiloClassCoachRoles.length && (
                  <Text color={'dimmed'} size='sm'>
                    -
                  </Text>
                )}
            </Group>
          </Stack>

          <Stack spacing='xs'>
            <Group spacing='xs' noWrap>
              <IconMapPinFilled size={theme.fontSizes.xl} className={classes.icon} />
              <Text color={location ? 'dark.5' : 'dimmed'} size='sm'>
                {location || '-'}
              </Text>
            </Group>
            <Group spacing='xs' noWrap>
              <IconUsers size={theme.fontSizes.xl} className={classes.icon} color={theme.colors.dark[2]} />
              <Text color='dark.5' size='sm'>
                {`${reservedAthletesCount} ${capacity ? `/ ${capacity} ` : ' '}`}
                {isClassAtCapacity && (
                  <Anchor sx={{ cursor: 'default' }} underline={false} color='red'>
                    (Class is at capacity)
                  </Anchor>
                )}
              </Text>
            </Group>
          </Stack>
        </Group>

        <Group ml='auto' position='right' spacing='xl' data-cy='actionButtonWrapper'>
          {isCheckedIn ? (
            <Button color='red' variant='filled' onClick={handleCheckOut} loading={isCheckoutButtonLoading}>
              Check out
            </Button>
          ) : (
            <Button color='gray' variant='default' onClick={handleCheckin} loading={isCheckinButtonLoading}>
              Check in
            </Button>
          )}
        </Group>
      </Flex>
    </Paper>
  );
};

export default EventCard;
