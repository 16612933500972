import React from 'react';

import { Burger, Group, Header as MantineHeader, MediaQuery } from '@mantine/core';
import { ReactComponent as KiloLogo } from 'assets/svg/long_kilo_blue_white.svg';
import { Link } from 'react-router-dom';

const Header = ({
  opened,
  handleBurgerClick,
}: {
  opened: boolean;
  handleBurgerClick: () => void;
}): JSX.Element | null => {
  return (
    <MantineHeader height={{ base: '3.8rem', sm: 0 }}>
      <MediaQuery largerThan='sm' styles={{ display: 'none' }}>
        <Group noWrap p='sm' align='start'>
          <Burger color='gray' opened={opened} onClick={handleBurgerClick} size='md' />
          <Link to='/'>
            <KiloLogo height='1.75rem' width='4.5rem' />
          </Link>
        </Group>
      </MediaQuery>
    </MantineHeader>
  );
};

export default Header;
