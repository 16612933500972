import { AxiosError, AxiosResponse } from 'axios';
import { useClient } from 'context/auth-context';
import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { AthleteKiloClass, AthleteKiloClassWrite, ErrorMessage } from 'types';

export default function useUpdateAthleteClass(): UseMutationResult<
  AxiosResponse<AthleteKiloClass>,
  AxiosError<ErrorMessage>,
  AthleteKiloClassWrite
> {
  const client = useClient();
  const queryClient = useQueryClient();

  const updateAthleteClass = (
    athleteKiloClass: AthleteKiloClassWrite,
  ): Promise<AxiosResponse<AthleteKiloClass>> => {
    return client.put(`athlete-kilo-classes/${athleteKiloClass?.athleteKiloClassId}/`, athleteKiloClass);
  };

  return useMutation(updateAthleteClass, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['athleteClasses'],
      });
      queryClient.invalidateQueries({
        queryKey: ['athleteEvents'],
      });
      queryClient.invalidateQueries({
        queryKey: ['classes'],
      });
      queryClient.invalidateQueries({
        queryKey: ['events'],
      });
      queryClient.invalidateQueries({
        queryKey: ['totalAttended'],
      });
      queryClient.invalidateQueries({
        queryKey: ['totalReserved'],
      });
    },
  });
}
