import React from 'react';

import { Routes as ReactRoutes, Route } from 'react-router-dom';
import { IRoute, IRouteMap } from 'routes/types';

const Routes = ({ routeMapList }: { routeMapList: IRouteMap[] }): JSX.Element => {
  return (
    <ReactRoutes>
      {routeMapList.map((routeMap, i) => (
        <Route key={i} element={<routeMap.layout navLinks={routeMap.navLinks} />}>
          {routeMap.routes.map(({ component: Component, path, key }: IRoute) => {
            return Component && path && <Route key={key} element={<Component />} path={path} />;
          })}
        </Route>
      ))}
    </ReactRoutes>
  );
};

export default Routes;
