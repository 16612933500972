import { useCallback } from 'react';

import { AxiosError, AxiosResponse } from 'axios';
import { useClient } from 'context/auth-context';
import { UseMutationResult, useMutation, useQueryClient } from 'react-query';
import { ErrorMessage } from 'types';

export default function useUploadProfilePicture(): UseMutationResult<
  AxiosResponse<{ url: string }>,
  AxiosError<ErrorMessage>,
  { file: File; userId: string }
> {
  const client = useClient();
  const queryClient = useQueryClient();

  const uploadProfilePicture = useCallback(
    ({ userId, file }: { userId: string; file: File }): Promise<AxiosResponse<{ url: string }>> => {
      const formData = new FormData();
      formData.append('file', file);

      return client.post(`auth/users/${userId}/profile_picture/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    },
    [client],
  );

  return useMutation(uploadProfilePicture, {
    onSuccess: () => {
      queryClient.invalidateQueries('user');
    },
  });
}
