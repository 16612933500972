import React, { useState } from 'react';

import {
  Button,
  Container,
  Group,
  Input,
  LoadingOverlay,
  Modal,
  ModalProps,
  PinInput,
  Stack,
  Title,
} from '@mantine/core';
import useSendSMSOTP from 'hooks/salesPortal/useSendSMSOTP';
import useValidateSMSOTP from 'hooks/salesPortal/useValidateSMSOTP';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import { phoneValidator } from 'utils/form-validators';
import { formatPhone } from 'utils/formatters';
import notificationHandler from 'utils/notifications';

interface IAccountVerificationProps extends Pick<ModalProps, 'opened' | 'onClose'> {
  setVerifiedPhoneNumber: (value: string) => void;
}

const AthletePhoneVerificationModal = ({
  opened,
  onClose,
  setVerifiedPhoneNumber,
}: IAccountVerificationProps): JSX.Element => {
  const sendSmsOtpMutation = useSendSMSOTP();
  const validateSmsOtpMutation = useValidateSMSOTP();

  const [phone, setPhone] = useState('');
  const [otp, setOTP] = useState('');
  const [verificationState, setVerificationState] = useState<'send' | 'validate'>('send');

  const { successNotification, errorNotification } = notificationHandler();
  const getPhoneValidator = phoneValidator(phone);
  const otpValidator = otp.length !== 6 ? 'OTP must be 6 digits' : null;

  const handleCloseAndReset = (): void => {
    setPhone('');
    setOTP('');
    setVerificationState('send');
    onClose();
  };

  const handeSendOtp = (): void => {
    const formattedPhone = formatPhone(phone);
    if (!!formattedPhone) {
      sendSmsOtpMutation.mutate(
        {
          phone: formattedPhone,
        },
        {
          onError: error => {
            if (error.response && error.response.data && error.response.data.message) {
              errorNotification(error.response.data.message);
            } else {
              errorNotification(
                'Something went wrong with sending the SMS. Please try again or contact support.',
              );
            }
          },
          onSuccess: () => {
            if (verificationState === 'send') {
              setVerificationState('validate');
            }
            successNotification('SMS sent successfully');
          },
        },
      );
    }
  };

  const handleValidateOtp = (): void => {
    validateSmsOtpMutation.mutate(
      {
        otp,
      },
      {
        onError: () => {
          errorNotification(
            'Something went wrong with validating the otp. Please try again or contact support.',
          );
        },
        onSuccess: () => {
          setVerifiedPhoneNumber(phone);
          handleCloseAndReset();
        },
      },
    );
  };

  const verifyContent = (
    <Container size='xs' w='100%'>
      <LoadingOverlay visible={sendSmsOtpMutation.isLoading} />
      <Stack>
        <Input.Wrapper required sx={{ flexGrow: 1 }}>
          <Input.Label required>Enter phone number to verify</Input.Label>
          <PhoneInput
            defaultCountry='US'
            value={phone}
            onChange={setPhone}
            placeholder='Enter Phone Number'
            inputProps={{
              style: {
                width: '100%',
              },
            }}
          />
        </Input.Wrapper>
        <Group mt='md' position='right'>
          <Button variant='subtle' onClick={handleCloseAndReset} disabled={sendSmsOtpMutation.isLoading}>
            Cancel
          </Button>
          <Button
            disabled={sendSmsOtpMutation.isLoading || !!getPhoneValidator}
            type='submit'
            onClick={handeSendOtp}
          >
            Send OTP
          </Button>
        </Group>
      </Stack>
    </Container>
  );

  const validateContent = (
    <Container size='xs'>
      <LoadingOverlay visible={validateSmsOtpMutation.isLoading} />
      <Stack>
        <Input.Wrapper required sx={{ alignSelf: 'center' }}>
          <Input.Label required>Verification Code</Input.Label>
          <PinInput length={6} placeholder='O' value={otp} onChange={setOTP} required type='number' />
        </Input.Wrapper>
        <Button onClick={handeSendOtp} variant='subtle' color='kiloblue'>
          Resend OTP
        </Button>
        <Group mt='md' position='right'>
          <Button variant='subtle' onClick={handleCloseAndReset} disabled={validateSmsOtpMutation.isLoading}>
            Cancel
          </Button>
          <Button
            disabled={validateSmsOtpMutation.isLoading || !!otpValidator}
            type='submit'
            onClick={handleValidateOtp}
          >
            Verify OTP
          </Button>
        </Group>
      </Stack>
    </Container>
  );

  return (
    <Modal
      radius='md'
      centered
      opened={opened}
      onClose={handleCloseAndReset}
      title={<Title order={4}>Change Phone Number</Title>}
    >
      {verificationState === 'validate' && validateContent}
      {verificationState === 'send' && verifyContent}
    </Modal>
  );
};

export default AthletePhoneVerificationModal;
