import dayjs from 'dayjs';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { PaymentMethod, PaymentMethodRetrieve } from 'types';

export const formatPhoneNumber = (phoneNumberString: string | undefined | null): string => {
  if (!phoneNumberString) {
    return '';
  }
  const phoneNumber = parsePhoneNumberFromString(`+${phoneNumberString}`);
  if (phoneNumber && phoneNumber.isValid() && phoneNumber.country) {
    return phoneNumber.formatNational();
  }
  return phoneNumberString;
};

export const formatDate = (date: string | null | undefined): string => {
  return date ? dayjs(date).format('MM/DD/YYYY') : '';
};

export const dollarFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',

  // These options are needed to round to whole numbers if that's what you want.
  minimumFractionDigits: 2, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  maximumFractionDigits: 2, // (causes 2500.99 to be printed as $2,501)
});

export const twoDecimalFormatter = (num: number): string => {
  return (Math.round(num * 100) / 100).toFixed(2);
};

export const threeDecimalFormatter = (num: number): string => {
  return (Math.round(num * 1000) / 1000).toFixed(3);
};

export const numberToOrdinalFormatter = (num: number): string => {
  if (num % 10 === 1 && num % 100 !== 11) {
    return 'st';
  }
  if (num % 10 === 2 && num % 100 !== 12) {
    return 'nd';
  }
  if (num % 10 === 3 && num % 100 !== 13) {
    return 'rd';
  }

  return 'th';
};

export const getPaymentMethodName = (paymentMethod: PaymentMethod | PaymentMethodRetrieve): string => {
  if (paymentMethod.nickname) {
    return paymentMethod.nickname;
  }
  let label = '';
  if (paymentMethod.type === 'CARD') {
    label = 'Card';
    if (paymentMethod.metadata && 'brand' in paymentMethod.metadata) {
      label = `${paymentMethod.metadata.brand.charAt(0).toUpperCase()}${paymentMethod.metadata.brand.slice(
        1,
      )}`;
    }
    if (paymentMethod.metadata && 'last4' in paymentMethod.metadata) {
      label += ` ${paymentMethod.metadata.last4}`;
    }
  }
  if (paymentMethod.type === 'US BANK ACCOUNT' || paymentMethod.type === 'ACSS DEBIT') {
    label = 'Bank Account';
    if (paymentMethod.metadata && 'bankName' in paymentMethod.metadata) {
      label = paymentMethod.metadata.bankName;
    }
  }
  return label;
};

export const formatPhone = (phone: string | null | undefined): string | null => {
  return phone && phone.length > 0 && phone !== '(___) ___-____' ? phone.replace(/[^\d]/g, '') : null;
};

export const camelCaseToTitleCaseText = (str: string): string => {
  const result = str.replace(/([A-Z])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
};
