/* eslint-env browser */
/*

 * Dotenv was removed as it was not used -- we get variables from runtimeProperties instead
 * of the .env file so there should be no problem
 * for future if we ever use variables directly from .env -- we need to prefix with REACT_APP
 * https://create-react-app.dev/docs/adding-custom-environment-variables/

API_SERVER - the URL of the API server
TOKEN_REFRESH_THRESHOLD - When the access token has this many seconds of lifetime remaining, we will refresh

*/

function addenv(property) {
  if (window === undefined || typeof window === 'undefined') {
    return process.env[property];
  }
  if (window.hasOwnProperty('runtimeProperties')) {
    return window.runtimeProperties[property];
  } else {
    return '';
  }
}

const config = {
  TOKEN_REFRESH_THRESHOLD: 3600,
  SHOW_RQ_DEVTOOLS: true,
  API_SERVER: addenv('APISERVER'),
  AUTHENTICATION_API: addenv('AUTHENTICATIONENDPOINT'),
  STRIPE_PUBLIC_KEY: addenv('STRIPEPUBLICKEY'),
  ENVIRONMENT: addenv('ENVIRONMENT'),
  TRACES_SAMPLE_RATE: parseFloat(addenv('TRACESSAMPLERATE')),
  SENTRY_DSN: addenv('SENTRYDSNREACT'),
  DOCKER_IMAGE_TAG: addenv('DOCKERIMAGETAG'),
  GOOGLEID: addenv('GOOGLECLIENTID'),
  FACEBOOKID: addenv('FACEBOOKID'),
  APPLEID: addenv('APPLEID'),
  SSO_LOGIN_ENABLED: addenv('SSOLOGINENABLED'),
};

export default {
  ...config,
};
