import React from 'react';

import AthleteMobileLayout from 'layouts/AthleteMobileLayout';
import { Navigate } from 'react-router-dom';

import { IRouteMap } from './types';

export const athleteRouteMap: IRouteMap = {
  layout: AthleteMobileLayout,
  routes: [
    {
      key: 'schedule',
      component: React.lazy(() => import('pages/private/athlete/Schedule')),
      path: '/',
    },
    {
      key: 'packages',
      component: React.lazy(() => import('pages/private/athlete/Packages')),
      path: '/packages',
    },
    {
      key: 'events',
      component: React.lazy(() => import('pages/private/athlete/Events')),
      path: '/events',
    },
    {
      key: 'class',
      component: React.lazy(() => import('pages/private/athlete/EventDetail')),
      path: '/classes/:eventId',
    },
    {
      key: 'appointment',
      component: React.lazy(() => import('pages/private/athlete/EventDetail')),
      path: '/appointments/:eventId',
    },
    {
      key: 'documents',
      component: React.lazy(() => import('pages/private/athlete/Documents')),
      path: '/documents',
    },
    {
      key: 'document',
      component: React.lazy(() => import('pages/private/athlete/DocumentDetail')),
      path: '/documents/:documentId',
    },
    {
      key: 'account',
      component: React.lazy(() => import('pages/private/athlete/Account')),
      path: '/account',
    },
    {
      key: 'redirect',
      component: () => Navigate({ to: '/' }),
      path: '*',
    },
  ],
  navLinks: [], // This is defined in MobileLayout and I didn't feel like changing it.
};

export const getAthleteRouteMap = (): IRouteMap => {
  return athleteRouteMap;
};
