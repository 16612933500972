import { useCallback } from 'react';

import { AxiosResponse } from 'axios';
import { useClient } from 'context/auth-context';
import { UseQueryResult, useQuery } from 'react-query';
import { Banner } from 'types/generated';

function useBanners(): UseQueryResult<AxiosResponse<Banner[]>> {
  const client = useClient();

  const fetchBanners = useCallback((): Promise<AxiosResponse<Banner[]>> => {
    return client.get(`banners/`);
  }, [client]);

  return useQuery(['banners'], fetchBanners, {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
}

export default useBanners;
