import { Ability, MongoQuery, Subject } from '@casl/ability';

type PossibleAbilities = [string, Subject];
type Conditions = MongoQuery;

export default new Ability<PossibleAbilities, Conditions>([
  {
    action: 'manage',
    subject: 'all',
    conditions: { isOwner: { $eq: true } },
  },
  {
    action: 'manage',
    subject: 'Dashboard',
    conditions: { isGymStaff: { $eq: true } },
  },
  {
    action: 'read',
    subject: 'BusinessMetricsReportsView',
    conditions: { isGymStaff: { $eq: true }, permissions: 'BusinessMetricsReportsView' },
  },
  {
    action: 'read',
    subject: 'AccountingReportsView',
    conditions: { isGymStaff: { $eq: true }, permissions: 'AccountingReportsView' },
  },
  {
    action: 'read',
    subject: 'PaymentReportsView',
    conditions: { isGymStaff: { $eq: true }, permissions: 'PaymentReportsView' },
  },
  {
    action: 'read',
    subject: 'AthleteReportsView',
    conditions: { isGymStaff: { $eq: true }, permissions: 'AthleteReportsView' },
  },
  {
    action: 'read',
    subject: 'PackageReportsView',
    conditions: { isGymStaff: { $eq: true }, permissions: 'PackageReportsView' },
  },
  {
    action: 'manage',
    subject: 'EventTypeLocationMutate',
    conditions: { isGymStaff: { $eq: true }, permissions: 'EventTypeLocationMutate' },
  },
  {
    action: 'manage',
    subject: 'EventMutate',
    conditions: { isGymStaff: { $eq: true }, permissions: 'EventMutate' },
  },
  {
    action: 'manage',
    subject: 'ProgramMutate',
    conditions: { isGymStaff: { $eq: true }, permissions: 'ProgramMutate' },
  },
  {
    action: 'manage',
    subject: 'Kiosk',
    conditions: { isGymStaff: { $eq: true }, permissions: 'Kiosk' },
  },
  {
    action: 'manage',
    subject: 'PackageMutate',
    conditions: { isGymStaff: { $eq: true }, permissions: 'PackageMutate' },
  },
  {
    action: 'manage',
    subject: 'ProductMutate',
    conditions: { isGymStaff: { $eq: true }, permissions: 'ProductMutate' },
  },
  {
    action: 'manage',
    subject: 'InvoiceCreate',
    conditions: { isGymStaff: { $eq: true }, permissions: 'InvoiceCreate' },
  },
  {
    action: 'manage',
    subject: 'TaxRateMutate',
    conditions: { isGymStaff: { $eq: true }, permissions: 'TaxRateMutate' },
  },
  {
    action: 'manage',
    subject: 'SalesPortalMutate',
    conditions: { isGymStaff: { $eq: true }, permissions: 'SalesPortalMutate' },
  },
  {
    action: 'read',
    subject: 'AthleteView',
    conditions: { isGymStaff: { $eq: true }, permissions: 'AthleteView' },
  },
  {
    action: 'read',
    subject: 'StaffView',
    conditions: { isGymStaff: { $eq: true }, permissions: 'StaffView' },
  },
  {
    action: 'manage',
    subject: 'StaffMutate',
    conditions: { isGymStaff: { $eq: true }, permissions: 'StaffMutate' },
  },
  {
    action: 'manage',
    subject: 'AthleteMutate',
    conditions: { isGymStaff: { $eq: true }, permissions: 'AthleteMutate' },
  },
  {
    action: 'manage',
    subject: 'AthletePackageMutate',
    conditions: { isGymStaff: { $eq: true }, permissions: 'AthletePackageMutate' },
  },
  {
    action: 'read',
    subject: 'InvoiceAthleteView',
    conditions: { isGymStaff: { $eq: true }, permissions: 'InvoiceAthleteView' },
  },
  {
    action: 'read',
    subject: 'InvoiceReportsView',
    conditions: { isGymStaff: { $eq: true }, permissions: 'InvoiceReportsView' },
  },
  {
    action: 'manage',
    subject: 'InvoiceMutate',
    conditions: { isGymStaff: { $eq: true }, permissions: 'InvoiceMutate' },
  },
  {
    action: 'read',
    subject: 'Users',
    conditions: {
      isGymStaff: { $eq: true },
      permissions: {
        $in: ['AthleteView', 'StaffView'],
      },
    },
  },
  {
    action: 'manage',
    subject: 'DocumentTemplateMutate',
    conditions: { isGymStaff: { $eq: true }, permissions: 'DocumentTemplateMutate' },
  },
  {
    action: 'manage',
    subject: 'IssuedDocumentMutate',
    conditions: { isGymStaff: { $eq: true }, permissions: 'IssuedDocumentMutate' },
  },
  {
    action: 'read',
    subject: 'FlowView',
    conditions: { isGymStaff: { $eq: true }, permissions: 'FlowView' },
  },
  {
    action: 'manage',
    subject: 'FlowMutate',
    conditions: { isGymStaff: { $eq: true }, permissions: 'FlowMutate' },
  },
  {
    action: 'read',
    subject: 'PayrollReportView',
    conditions: { isGymStaff: { $eq: true }, permissions: 'PayrollReportView' },
  },
  {
    action: 'read',
    subject: 'Payroll',
    conditions: { isGymStaff: { $eq: true }, permissions: 'PayrollReportView' },
  },
  {
    action: 'manage',
    subject: 'PayrollMutate',
    conditions: { isGymStaff: { $eq: true }, permissions: 'PayrollMutate' },
  },
  {
    action: 'manage',
    subject: 'GymMutate',
    conditions: { isGymStaff: { $eq: true }, permissions: 'GymMutate' },
  },
  {
    action: 'manage',
    subject: 'Customers',
    conditions: { isKiloStaff: { $eq: true } },
  },
  {
    action: 'manage',
    subject: 'Gyms',
    conditions: { isKiloStaff: { $eq: true } },
  },
]);
