import React from 'react';

import {
  createStyles,
  Drawer as MantineDrawer,
  DrawerProps as MantineDrawerProps,
  useMantineTheme,
} from '@mantine/core';
import BorderSize from 'types/enums';

const useStyles = createStyles((theme, { padding = '' }: { padding: string | null }) => ({
  content: {
    display: 'grid',
    gridTemplateRows: 'max-content auto max-content',
  },
  header: {
    borderBottom: `${BorderSize.md}px solid ${theme.colors.gray[2]}`,
    padding: theme.spacing.md,
  },
  body: {
    position: 'relative',
    padding: `${padding || theme.spacing.xl} !important`,
    height: '100%',
    overflowY: 'auto',
    '::-webkit-scrollbar-thumb': {
      backgroundColor: theme.colors.dark[1],
      borderRadius: theme.radius.xl,
      opacity: 0.7,
      border: `${BorderSize.xl}px solid ${theme.white}`,
    },
    '::-webkit-scrollbar': {
      width: 15,
    },
  },
  footer: {
    position: 'sticky',
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: theme.white,
    borderTop: `${BorderSize.md}px solid ${theme.colors.gray[2]}`,
    padding: padding || theme.spacing.md,
    zIndex: 1,
  },
}));

type DrawerProps = MantineDrawerProps & {
  header?: JSX.Element | null;
  closeButtonSection?: JSX.Element;
  footer?: React.ReactNode;
  padding?: string;
};

const Drawer = ({
  children,
  title,
  header = null,
  footer,
  closeButtonSection = <MantineDrawer.CloseButton />,
  padding = '',
  ...drawerProps
}: DrawerProps): JSX.Element => {
  const theme = useMantineTheme();

  const { classes } = useStyles({ padding });

  return (
    <MantineDrawer.Root position='right' padding='md' transitionProps={{ duration: 120 }} {...drawerProps}>
      <MantineDrawer.Overlay color={theme.colors.gray[2]} opacity={0.55} blur={3} />
      <MantineDrawer.Content className={classes.content}>
        {header ? (
          <div className={classes.header}>{header}</div>
        ) : (
          <MantineDrawer.Header className={classes.header}>
            <MantineDrawer.Title>{title}</MantineDrawer.Title>
            {closeButtonSection}
          </MantineDrawer.Header>
        )}
        <MantineDrawer.Body className={classes.body}>{children}</MantineDrawer.Body>
        {footer && <div className={classes.footer}>{footer}</div>}
      </MantineDrawer.Content>
    </MantineDrawer.Root>
  );
};

export default Drawer;
