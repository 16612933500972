import React from 'react';

import { createStyles, Flex, SegmentedControl, Text } from '@mantine/core';
import {
  IconFileInvoice,
  IconArchive,
  IconUserCircle,
  IconCalendar,
  IconCalendarCheck,
} from '@tabler/icons-react';
import useCurrentRoute from 'hooks/useCurrentRoute';
import { useNavigate } from 'react-router-dom';
import BorderSize from 'types/enums';

const useStyles = createStyles(theme => ({
  root: {
    backgroundColor: theme.white,
    boxShadow: theme.shadows.md,
    border: `${BorderSize.sm} solid ${theme.colors.gray[1]}`,

    '.mantine-SegmentedControl-control:not(:first-of-type)': {
      borderWidth: 0,
    },
  },
  label: {
    '&, &:hover': {
      '&[data-active]': {
        color: theme.white,
      },
    },
  },
}));

const Navbar = (): JSX.Element | null => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const pathname = useCurrentRoute();

  const handleChange = (value: string): void => {
    navigate(value);
  };

  const linksData = [
    {
      Icon: IconArchive,
      label: 'Packages',
      value: '/packages',
    },
    {
      Icon: IconCalendarCheck,
      label: 'My Events',
      value: '/events',
    },
    {
      Icon: IconCalendar,
      label: 'Schedule',
      value: '/',
    },
    {
      Icon: IconFileInvoice,
      label: 'Documents',
      value: '/documents',
    },
    {
      Icon: IconUserCircle,
      label: 'Account',
      value: '/account',
    },
  ];

  const links = linksData.map(({ Icon, label, value }) => ({
    label: (
      <Flex direction='column' align='center' gap={2}>
        <Icon size='1.625rem' />
        <Text size='xs' weight={600}>
          {label}
        </Text>
      </Flex>
    ),
    value,
  }));

  return (
    <div style={{ width: '100%' }}>
      <SegmentedControl
        value={pathname}
        color='kiloBlue'
        radius='lg'
        size='sm'
        mx='xs'
        mb='sm'
        data={links}
        classNames={classes}
        onChange={handleChange}
        fullWidth
      />
    </div>
  );
};

export default Navbar;
