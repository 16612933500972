import { useClient } from 'context/auth-context';
import { useQuery, UseQueryResult } from 'react-query';
import { GymRetrieve } from 'types';

function useGym(gymId: string | null): UseQueryResult<{ data: GymRetrieve }> {
  const client = useClient();
  return useQuery(['gyms', gymId], () => client.get(`gyms/${gymId}/`), {
    enabled: !!gymId,
  });
}

export default useGym;
