import { useCallback } from 'react';

import { AxiosError, AxiosResponse } from 'axios';
import { useClient } from 'context/auth-context';
import { useMutation, useQueryClient, UseMutationResult } from 'react-query';
import { ErrorMessage, GoogleCalendarIntegration, GoogleCalendarIntegrationCreate } from 'types';

export default function useCreateGoogleCalendarIntegration(): UseMutationResult<
  AxiosResponse<GoogleCalendarIntegration>,
  AxiosError<ErrorMessage>,
  GoogleCalendarIntegrationCreate
> {
  const client = useClient();
  const queryClient = useQueryClient();

  const createGoogleCalendarIntegration = useCallback(
    (
      googleCalendarIntegration: GoogleCalendarIntegrationCreate,
    ): Promise<AxiosResponse<GoogleCalendarIntegration>> => {
      return client.post(`google-calendar-integrations/`, googleCalendarIntegration);
    },
    [client],
  );

  return useMutation(createGoogleCalendarIntegration, {
    onSuccess: () => {
      queryClient.invalidateQueries('googleCalendarIntegrations');
    },
  });
}
