export type TimePeriod = TimeUnitEnum.MINUTE | TimeUnitEnum.HOUR | TimeUnitEnum.DAY;

export enum TimeUnitEnum {
  MINUTE = 'minute',
  HOUR = 'hour',
  DAY = 'day',
}

enum BorderSize {
  xs = 0,
  sm = 1,
  md = 2,
  lg = 3,
  xl = 4,
}

export default BorderSize;
