import React from 'react';

import { Text, Button, Group, Stack, Title, useMantineTheme } from '@mantine/core';
import { IconBox, IconPlus } from '@tabler/icons-react';

const EmptyState = ({
  title,
  description,
  buttonLabel,
  addHandler,
  icon,
}: {
  title: string;
  description: string;
  buttonLabel?: string;
  addHandler?: () => void;
  icon?: JSX.Element;
}): JSX.Element => {
  const theme = useMantineTheme();
  return (
    <Stack spacing='sm'>
      {icon ?? <IconBox size={theme.fontSizes.xl} />}
      <Title order={3}>{title}</Title>
      <Text color='dimmed'>{description}</Text>
      {buttonLabel && addHandler && (
        <Group>
          <Button leftIcon={<IconPlus size={theme.fontSizes.sm} />} onClick={addHandler}>
            {buttonLabel}
          </Button>
        </Group>
      )}
    </Stack>
  );
};

export default EmptyState;
