import React from 'react';

import { Input, InputProps } from '@mantine/core';
import InputMask from 'react-input-mask';

interface IPostalCodeInputProps extends Omit<InputProps, 'data' | 'rightSection'> {
  country: string;
  formErrors?: React.ReactNode;
}

const PostalCodeInput = ({ country, formErrors, ...inputProps }: IPostalCodeInputProps): JSX.Element => {
  return (
    <Input.Wrapper>
      {country === 'US' && <Input.Label>Zip Code</Input.Label>}
      {country === 'CA' && <Input.Label>Postal Code</Input.Label>}
      <Input
        component={InputMask}
        mask={country === 'US' ? '99999' : 'a9a 9a9'}
        placeholder={country == 'US' ? 'Enter Zip Code' : 'Enter Postal Code'}
        {...inputProps}
      />
      {formErrors && <Input.Error>{formErrors}</Input.Error>}
    </Input.Wrapper>
  );
};

export default PostalCodeInput;
