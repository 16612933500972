import React from 'react';

import { Loader, Avatar as MantineAvatar, createStyles, useMantineTheme } from '@mantine/core';
import { DropzoneProps, FileRejection, FileWithPath } from '@mantine/dropzone';
import { useHover } from '@mantine/hooks';
import { IconPencil } from '@tabler/icons-react';

import FileUpload from './FileUpload';

const useStyles = createStyles(theme => ({
  avatarContainer: {
    position: 'relative',
    borderRadius: '100%',
    cursor: 'pointer',
    overflow: 'hidden',
  },
  pencilIconContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: theme.fn.rgba(theme.colors.dark[4], 0.5),

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  root: {
    paddingTop: 80,
    paddingBottom: 80,
  },
}));

interface IAvatarProps {
  handleFileChange: (arg: FileWithPath[]) => void;
  imgSrc: string;
  handleFileReject?: (files: FileRejection[]) => void;
  disabled?: boolean;
  loading?: boolean;
  children?: DropzoneProps['children'];
}

const Avatar = ({
  handleFileChange,
  imgSrc,
  handleFileReject,
  disabled,
  children,
  loading = false,
}: IAvatarProps): JSX.Element => {
  const { classes } = useStyles();
  const { hovered, ref } = useHover();
  const theme = useMantineTheme();

  return (
    <div ref={ref}>
      <FileUpload
        src={
          <div className={classes.avatarContainer}>
            <MantineAvatar src={imgSrc} variant='outline' color='kiloBlue' radius='100%' size={200} />

            {(loading || hovered) && (
              <div className={classes.pencilIconContainer}>
                {loading ? (
                  <Loader size={50} color={theme.white} />
                ) : (
                  <IconPencil size={50} color={theme.white} />
                )}
              </div>
            )}
          </div>
        }
        onDrop={handleFileChange}
        disabled={disabled}
        handleFileReject={handleFileReject}
        sx={{
          border: 0,
          borderRadius: '100%',
        }}
      >
        {children}
      </FileUpload>
    </div>
  );
};

export default Avatar;
