import React from 'react';

import { useAbility } from '@casl/react';
import { useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { Notifications } from '@mantine/notifications';
import * as Sentry from '@sentry/react';
import { AbilityContext } from 'Can';
import { useAuth } from 'context/auth-context';
import useGym from 'hooks/gym/useGym';
import Error from 'pages/shared/Error';
import Loading from 'pages/shared/Loading';
import { getRouteMapList } from 'routes/index';
import Routes from 'routes/render-routes';

const Router = (): JSX.Element => {
  const { isAuthenticated, isLoading, user } = useAuth();
  const isKiosk = localStorage.getItem('isKiosk') === 'true';
  const gymHook = useGym(user?.gym);
  const theme = useMantineTheme();
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.sm}`, true);
  // TODO: Fix abilities. This should accept a user and provide a global context
  const ability = useAbility(AbilityContext);

  const routeMapList = getRouteMapList(
    user,
    isLoading,
    isAuthenticated,
    isKiosk,
    gymHook,
    ability,
    isDesktop,
  );

  return (
    <Sentry.ErrorBoundary fallback={<Error />}>
      <Notifications
        data-cy='notificationsContainer'
        position={user?.isAthlete ? 'top-center' : 'bottom-left'}
      />
      <React.Suspense fallback={<Loading />}>
        <Routes routeMapList={routeMapList} />
      </React.Suspense>
    </Sentry.ErrorBoundary>
  );
};

export default Router;
