import React from 'react';

import { createStyles, Title, Text, Button, Container, Group } from '@mantine/core';

const useStyles = createStyles(theme => ({
  root: {
    paddingTop: 80,
    paddingBottom: 80,
  },

  label: {
    textAlign: 'center',
    fontWeight: 900,
    fontSize: 220,
    lineHeight: 1,
    marginBottom: `calc(${theme.spacing.xl} * 1.5)`,
    color: theme.colors.gray[2],

    [theme.fn.smallerThan('sm')]: {
      fontSize: 120,
    },
  },

  title: {
    textAlign: 'center',
    fontWeight: 900,
    fontSize: 38,

    [theme.fn.smallerThan('sm')]: {
      fontSize: 32,
    },
  },

  description: {
    maxWidth: 500,
    margin: 'auto',
    marginTop: theme.spacing.xl,
    marginBottom: `calc(${theme.spacing.xl} * 1.5)`,
  },
}));

const Error = (): JSX.Element => {
  const { classes } = useStyles();

  const handleOnClick = (): void => {
    window.location.reload();
  };

  return (
    <Container className={classes.root}>
      <div className={classes.label}>500</div>
      <Title className={classes.title}>Something bad just happened...</Title>
      <Text color='dimmed' size='lg' align='center' className={classes.description}>
        Our servers could not handle your request. Don&apos;t worry, our development team was already
        notified. Try refreshing the page.
      </Text>
      <Group position='center'>
        <Button variant='subtle' size='md' onClick={handleOnClick}>
          Refresh the page
        </Button>
      </Group>
    </Container>
  );
};

export default Error;
