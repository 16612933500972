import React from 'react';

import { Button, createStyles, Group, Input, Loader } from '@mantine/core';
import { FormErrors } from '@mantine/form/lib/types';
import { useDisclosure } from '@mantine/hooks';
import { useAuth } from 'context/auth-context';
import useGym from 'hooks/gym/useGym';
import { PhoneInput, PhoneInputProps } from 'react-international-phone';

import AthletePhoneVerificationModal from './AthletePhoneVerificationModal';

interface AthletePhoneFieldProps {
  error: FormErrors['value'];
  value: PhoneInputProps['value'];
  onChange: (value: string) => void;
}

const useStyles = createStyles(theme => ({
  phoneInputContainer: {
    flexGrow: 1,
  },
  phoneInput: {
    width: '100%',
  },
  button: {
    flexGrow: 1,
  },
  container: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'end',

    [theme.fn.smallerThan('xs')]: {
      flexDirection: 'column',
      alignItems: 'baseline',
    },
  },
}));

const AthletePhoneField = ({ error, value, onChange }: AthletePhoneFieldProps): JSX.Element => {
  const { classes } = useStyles();
  const { user } = useAuth();

  const { data: gymData, isLoading: isGymLoading, isError: isGymError } = useGym(user.gym);

  const [
    isPhoneVerifyModalOpened,
    { open: openPhoneVerifyModalOpened, close: closePhoneVerifyModalOpened },
  ] = useDisclosure(false);

  const isPhoneVerificationRequired =
    !!gymData?.data.additionalSettings?.requireAthletePhoneNumberVerificationPerChange;

  return (
    <>
      <Group className={classes.container}>
        <Input.Wrapper error={error} className={classes.phoneInputContainer}>
          <Input.Label>Phone Number {isGymLoading ? <Loader size='sm' /> : null}</Input.Label>
          <PhoneInput
            defaultCountry='US'
            placeholder='Enter Phone Number'
            value={value}
            onChange={onChange}
            inputClassName={classes.phoneInput}
            disabled={isGymLoading || isGymError || isPhoneVerificationRequired}
          />
        </Input.Wrapper>

        {isPhoneVerificationRequired && (
          <Button variant='default' onClick={openPhoneVerifyModalOpened} className={classes.button}>
            {!!value ? 'Change phone number' : 'Add phone number'}
          </Button>
        )}
      </Group>

      <AthletePhoneVerificationModal
        setVerifiedPhoneNumber={onChange}
        opened={isPhoneVerifyModalOpened}
        onClose={closePhoneVerifyModalOpened}
      />
    </>
  );
};

export default AthletePhoneField;
