import { matchRoutes, useLocation } from 'react-router-dom';
import { athleteRouteMap } from 'routes/athlete';

// I'm refactoring this so that it still works and I don't have to rewrite all of the athlete views
// TODO: remove this hook, pass route/nav information to MobileLayout, refactor Mobile Header
export default function useCurrentRoute(): string {
  const location = useLocation();
  const routes = athleteRouteMap.routes;
  const matchedRoutes = matchRoutes(
    routes.map(route => ({ path: route.path })),
    location,
  );

  if (Array.isArray(matchedRoutes)) {
    return matchedRoutes[0].route.path;
  }

  return '';
}
