import React from 'react';

import { Group, Paper, PaperProps, Stack } from '@mantine/core';

interface IInfoCardProps extends PaperProps {
  header: JSX.Element | string;
  actionButton?: JSX.Element;
  content: JSX.Element;
  onClickHandler?: () => void;
}

const InfoCard = ({
  header,
  actionButton = <></>,
  content,
  onClickHandler = () => {},
  ...props
}: IInfoCardProps): JSX.Element => {
  return (
    <Paper
      shadow='xl'
      radius='md'
      withBorder
      py='sm'
      px={0}
      miw='fit-content'
      h='fit-content'
      onClick={onClickHandler}
      {...props}
    >
      <Stack spacing='sm'>
        <Group position='apart' px='md' data-cy='athleteStatusBadge'>
          {header}
          {actionButton}
        </Group>
        {content}
      </Stack>
    </Paper>
  );
};

export default InfoCard;
