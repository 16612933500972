import React from 'react';

import { Button, Group, Input, Stack, Textarea, TextInput } from '@mantine/core';
import { DateInput } from '@mantine/dates';
import { useForm } from '@mantine/form';
import { IconCalendar, IconHome, IconMail, IconTags } from '@tabler/icons-react';
import StateAutoComplete from 'components/form-fields/auto-complete-fields/StateAutoComplete';
import PostalCodeInput from 'components/form-fields/input-fields/PostalCodeInput';
import CanadaProvinceTerritorySelect from 'components/form-fields/select-fields/CanadaProvinceTerritorySelect';
import CountrySelect from 'components/form-fields/select-fields/CountrySelect';
import { useAuth } from 'context/auth-context';
import dayjs from 'dayjs';
import useUpdateUser from 'hooks/auth/useUpdateUser';
import AthletePhoneField from 'pages/private/athlete/Account/components/AthletePhoneField';
import { PhoneInput } from 'react-international-phone';
import { emailValidator, nameValidator, phoneValidator } from 'utils/form-validators';
import { formatPhone } from 'utils/formatters';
import notificationHandler from 'utils/notifications';

interface IStaffAccountGeneralDetailsFormProps {
  isEditableAccountDetails: boolean;
  setIsEditableAccountDetails: (isEditable: boolean) => void;
}

interface StaffGeneralFormValues {
  userId: string;
  email: string;
  firstName: string;
  lastName: string;
  phone: string | null;
  dob: Date | null;
  address: {
    addressLines: string | null;
    locality: string | null;
    region: string | null;
    zipCode: string | null;
    country: 'CA' | 'US';
  };
  emergencyName: string | null;
  emergencyPhone: string | null;
  notes: string | null;
}

const StaffAccountGeneralDetails = ({
  isEditableAccountDetails,
  setIsEditableAccountDetails,
}: IStaffAccountGeneralDetailsFormProps): JSX.Element => {
  const { user } = useAuth();

  const updateMutation = useUpdateUser();
  const { successNotification, errorNotification } = notificationHandler();

  const staffGeneralForm = useForm<StaffGeneralFormValues>({
    initialValues: {
      userId: user.userId,
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      phone: user.phone,
      dob: user.dob ? dayjs(user.dob).toDate() : null,
      address: {
        addressLines: user.address?.addressLines,
        locality: user.address?.locality,
        region: user.address?.region,
        zipCode: user.address?.zipCode,
        country: user.address?.country || 'US',
      },
      emergencyName: user.emergencyName,
      emergencyPhone: user.emergencyPhone,
      notes: user.notes,
    },

    validate: {
      firstName: value => nameValidator(value),
      lastName: value => nameValidator(value),
      email: value => emailValidator(value),
      phone: value => (value ? phoneValidator(value) : null),
      emergencyName: value =>
        value && value.length > 128 ? 'Emergency name must be between 1-128 characters' : null,
      emergencyPhone: value => (value ? phoneValidator(value) : null),
    },
    transformValues: (values: StaffGeneralFormValues): StaffGeneralFormValues => ({
      ...values,
      phone: formatPhone(values.phone),
      emergencyPhone: formatPhone(values.emergencyPhone),
      address: {
        ...values.address,
        zipCode: values.address.zipCode?.toUpperCase() || null,
      },
    }),
  });

  const handleResetStaffGeneralForm = (): void => {
    staffGeneralForm.setValues(user);
    setIsEditableAccountDetails(false);
  };

  const handleStaffGeneralSubmit = (formValues: StaffGeneralFormValues): void => {
    if (
      formValues.email !== user.email ||
      formValues.firstName !== user.firstName ||
      formValues.lastName !== user.lastName ||
      formValues.address?.addressLines !== user.address?.addressLines ||
      formValues.address?.locality !== user.address?.locality ||
      formValues.address?.region !== user.address?.region ||
      formValues.address?.zipCode !== user.address?.zipCode ||
      formValues.phone !== user.phone ||
      formValues.emergencyName !== user.emergencyName ||
      formValues.emergencyPhone !== user.emergencyPhone ||
      formValues.notes !== user.notes ||
      formValues.dob !== user.dob
    ) {
      updateMutation.mutate(
        {
          ...user,
          ...formValues,
          username: formValues.email,
          dob: formValues.dob ? dayjs(formValues.dob).format('YYYY-MM-DD') : null,
          phone: formValues.phone && formValues.phone.length > 10 ? formValues.phone : '',
          emergencyPhone:
            formValues.emergencyPhone && formValues.emergencyPhone.length > 10
              ? formValues.emergencyPhone
              : '',
          hasUnusableEmail: false,
        },
        {
          onError: error => {
            errorNotification('Account details update failed', error.response?.data.message);
          },
          onSuccess: () => {
            successNotification('Account details update success');
            handleResetStaffGeneralForm();
          },
        },
      );
    }
  };

  return (
    <form id='gym-staff-account-details-form' onSubmit={staffGeneralForm.onSubmit(handleStaffGeneralSubmit)}>
      <Stack spacing='xs' p='lg'>
        <Group grow spacing='xs'>
          <TextInput
            label='First Name'
            placeholder='Enter First Name'
            required={isEditableAccountDetails}
            disabled={!isEditableAccountDetails}
            icon={<IconTags />}
            {...staffGeneralForm.getInputProps('firstName')}
          />
          <TextInput
            label='Last Name'
            placeholder='Enter Last Name'
            required={isEditableAccountDetails}
            disabled={!isEditableAccountDetails}
            icon={<IconTags />}
            {...staffGeneralForm.getInputProps('lastName')}
          />
        </Group>
        <TextInput
          type='email'
          label='Email'
          placeholder='Enter Email'
          required={isEditableAccountDetails}
          disabled={!isEditableAccountDetails}
          icon={<IconMail />}
          {...staffGeneralForm.getInputProps('email')}
        />
        {isEditableAccountDetails && (
          <>
            <AthletePhoneField
              error={staffGeneralForm.errors.phone}
              value={staffGeneralForm.values.phone || ''}
              onChange={value => staffGeneralForm.setFieldValue('phone', value)}
            />
            <DateInput
              label='Date of Birth'
              placeholder='MM/DD/YYYY'
              valueFormat='MM/DD/YYYY'
              clearable
              defaultLevel='decade'
              disabled={!isEditableAccountDetails}
              maxDate={dayjs().toDate()}
              icon={<IconCalendar />}
              firstDayOfWeek={0}
              {...staffGeneralForm.getInputProps('dob')}
            />
            <TextInput
              type='text'
              label='Address'
              placeholder='Enter Address'
              disabled={!isEditableAccountDetails}
              icon={<IconHome />}
              {...staffGeneralForm.getInputProps('address.addressLines')}
            />
            <TextInput
              type='text'
              label='City'
              placeholder='Enter City'
              disabled={!isEditableAccountDetails}
              icon={<IconHome />}
              {...staffGeneralForm.getInputProps('address.locality')}
            />
            <Group grow spacing='xs'>
              {staffGeneralForm.values.address.country == 'US' && (
                <StateAutoComplete
                  icon={<IconHome />}
                  clearable
                  disabled={!isEditableAccountDetails}
                  {...staffGeneralForm.getInputProps('address.region')}
                />
              )}
              {staffGeneralForm.values.address.country == 'CA' && (
                <CanadaProvinceTerritorySelect
                  icon={<IconHome />}
                  clearable
                  disabled={!isEditableAccountDetails}
                  {...staffGeneralForm.getInputProps('address.region')}
                />
              )}
              <PostalCodeInput
                country={staffGeneralForm.values.address.country}
                icon={<IconHome />}
                disabled={!isEditableAccountDetails}
                {...staffGeneralForm.getInputProps('address.zipCode')}
                formErrors={staffGeneralForm.errors.postalCode}
              />
              <CountrySelect
                label='Country'
                sx={{ flex: 1 }}
                clearable
                value={staffGeneralForm.values.address.country}
                onChange={value => {
                  staffGeneralForm.setFieldValue('address.region', '');
                  staffGeneralForm.setFieldValue('address.zipCode', '');
                  staffGeneralForm.setFieldValue('address.country', value);
                }}
              />
            </Group>
            <TextInput
              type='text'
              label='Emergency Contact'
              placeholder='Enter Full Name'
              disabled={!isEditableAccountDetails}
              icon={<IconTags />}
              {...staffGeneralForm.getInputProps('emergencyName')}
            />
            <Input.Wrapper error={staffGeneralForm.getInputProps('emergencyPhone').error}>
              <Input.Label>Emergency Phone</Input.Label>
              <PhoneInput
                defaultCountry='US'
                value={staffGeneralForm.values.emergencyPhone || ''}
                onChange={value => staffGeneralForm.setFieldValue('emergencyPhone', value)}
                placeholder='Enter Phone Number'
              />
            </Input.Wrapper>
            <Textarea
              label='Medical Notes'
              placeholder='Enter Medical Notes'
              disabled={!isEditableAccountDetails}
              {...staffGeneralForm.getInputProps('notes')}
            />
          </>
        )}
        <Group position='right'>
          {isEditableAccountDetails ? (
            <>
              <Button
                variant='light'
                onClick={handleResetStaffGeneralForm}
                disabled={updateMutation.isLoading}
              >
                Cancel
              </Button>
              <Button form='gym-staff-account-details-form' type='submit' loading={updateMutation.isLoading}>
                Save
              </Button>
            </>
          ) : (
            <Button onClick={() => setIsEditableAccountDetails(true)}>Edit General</Button>
          )}
        </Group>
      </Stack>
    </form>
  );
};

export default StaffAccountGeneralDetails;
