import React, { useState } from 'react';

import {
  Button,
  Checkbox,
  Divider,
  Flex,
  Group,
  PasswordInput,
  px,
  Stack,
  Text,
  TextInput,
  Title,
  Tooltip,
  useMantineTheme,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useDisclosure, useShallowEffect } from '@mantine/hooks';
import { useGoogleLogin } from '@react-oauth/google';
import { IconBrandGoogle } from '@tabler/icons-react';
import Drawer from 'components/Drawer';
import ExpandableSection from 'components/ExpandableSection';
import InfoCard from 'components/InfoCard';
import InfoCardSection from 'components/InfoCard/InfoCardSection';
import Avatar from 'components/upload/Avatar';
import { useAuth } from 'context/auth-context';
import useUploadProfilePicture from 'hooks/account/useUploadProfilePicture';
import useChangePassword from 'hooks/auth/useChangePassword';
import useUpdateUser from 'hooks/auth/useUpdateUser';
import useCreateGoogleCalendarIntegration from 'hooks/googleCalendarIntegration/useCreateGoogleCalendarIntegration';
import useGoogleCalendarIntegrations from 'hooks/googleCalendarIntegration/useGoogleCalendarIntegrations';
import useUpdateGoogleCalendarIntegration from 'hooks/googleCalendarIntegration/useUpdateGoogleCalendarIntegration';
import { emailValidator, nameValidator } from 'utils/form-validators';
import notificationHandler from 'utils/notifications';

import StaffAccountGeneralDetails from './StaffAccountGeneralDetails';

interface FormAccountDetailsValues {
  userId: string;
  email: string;
  firstName: string;
  lastName: string;
}

interface FormPasswordValues {
  currentPassword: string;
  newPassword: string;
  reNewPassword: string;
}

interface FormGoogleCalendarIntegrationValues {
  googleCalendarIntegrationId: string;
  syncAppointmentsToCalendar: boolean;
  syncClassesToCalendar: boolean;
  authorizationCode: string | null;
  email: string;
}

interface IDrawerAccountDetailsFormProps {
  title: JSX.Element;
  opened: boolean;
  closeHandler: () => void;
  isGymStaff: boolean;
}

const DrawerAccountDetails = ({
  title,
  opened,
  closeHandler,
  isGymStaff = false,
}: IDrawerAccountDetailsFormProps): JSX.Element => {
  const { user } = useAuth();
  const theme = useMantineTheme();
  const { data: googleCalendarIntegrationsData, isLoading: googleCalendarIntegrationsLoading } =
    useGoogleCalendarIntegrations({
      user: user.userId,
      enabled: user.isCoach,
    });
  const googleCalendarIntegration =
    googleCalendarIntegrationsData && googleCalendarIntegrationsData.pages[0].data.results[0];

  const { successNotification, errorNotification } = notificationHandler();

  const [editableAccountDetails, setEditableAccountDetails] = useState(false);
  const [editablePassword, setEditablePassword] = useState(false);
  const [integrationsOpened, { toggle: handleIntegrationsToggle, close: closeIntegrations }] =
    useDisclosure();

  const updateMutation = useUpdateUser();
  const createGoogleIntegrationMutation = useCreateGoogleCalendarIntegration();
  const updateGoogleIntegrationMutation = useUpdateGoogleCalendarIntegration();
  const changePasswordMutation = useChangePassword();

  const usernameForm = useForm<FormAccountDetailsValues>({
    initialValues: { userId: '', email: '', firstName: '', lastName: '' },

    validate: {
      firstName: value => nameValidator(value),
      lastName: value => nameValidator(value),
      email: value => emailValidator(value),
    },
  });

  const passwordForm = useForm<FormPasswordValues>({
    initialValues: { currentPassword: '', newPassword: '', reNewPassword: '' },

    validate: {
      currentPassword: value => (value ? null : 'Required'),
      newPassword: value => (value ? null : 'Required'),
      reNewPassword: value => (value ? null : 'Required'),
    },
  });

  const googleCalendarIntegrationForm = useForm<FormGoogleCalendarIntegrationValues>({
    initialValues: {
      googleCalendarIntegrationId: '',
      syncAppointmentsToCalendar: false,
      syncClassesToCalendar: false,
      authorizationCode: null,
      email: '',
    },
  });

  const uploadMutation = useUploadProfilePicture();

  const [imgUrl, setImgUrl] = useState<string | null>(user.profilePicture);

  const handleProfileUpload = (file: File | null): void => {
    if (!file) {
      return;
    }
    if (file.size > 5000000) {
      errorNotification('The selected file is too large. Please select a file smaller than 5MB.');
      return;
    }

    uploadMutation.mutate(
      { userId: user.userId, file },
      {
        onError: error => {
          errorNotification('Profile picture upload failed', error.response?.data.message);
        },
        onSuccess: res => {
          successNotification('Profile picture upload success');
          setImgUrl(res.data.url);
        },
      },
    );
  };

  const handleResetPasswordForm = (): void => {
    passwordForm.reset();
    setEditablePassword(false);
  };

  const handleResetUsernameForm = (): void => {
    usernameForm.setValues(user);
    setEditableAccountDetails(false);
  };

  const handleResetGoogleCalendarIntegrationForm = (): void => {
    if (googleCalendarIntegration) {
      googleCalendarIntegrationForm.setValues(googleCalendarIntegration);
    }
  };

  const handleCloseAndReset = (): void => {
    closeHandler();
    handleResetUsernameForm();
    handleResetPasswordForm();
    handleResetGoogleCalendarIntegrationForm();
    closeIntegrations();
  };

  const handleAccountDetailsSubmit = (payload: FormAccountDetailsValues): void => {
    setEditableAccountDetails(false);

    if (
      payload.email !== user.email ||
      payload.firstName !== user.firstName ||
      payload.lastName !== user.lastName
    ) {
      user.email = payload.email;
      user.username = payload.email;
      user.firstName = payload.firstName;
      user.lastName = payload.lastName;
    } else {
      return;
    }

    updateMutation.mutate(user, {
      onError: error => {
        errorNotification('Account details update failed', error.response?.data.message);
      },
      onSuccess: () => {
        successNotification('Account details update success');
        handleResetUsernameForm();
      },
    });
  };

  const handlePasswordSubmit = (payload: FormPasswordValues): void => {
    changePasswordMutation.mutate(payload, {
      onError: (error: any) => {
        if (error.response?.data.currentPassword) {
          passwordForm.setFieldError('currentPassword', error.response?.data.currentPassword);
        } else if (error.response?.data.newPassword) {
          passwordForm.setFieldError('newPassword', error.response?.data.newPassword);
        } else if (error.response?.data.reNewPassword) {
          passwordForm.setFieldError('reNewPassword', error.response?.data.reNewPassword);
        } else if (error.response?.data.nonFieldErrors) {
          passwordForm.setFieldError('reNewPassword', error.response?.data.nonFieldErrors);
        } else {
          errorNotification('Password change attempt failed', error.response?.data.message);
        }
      },
      onSuccess: () => {
        successNotification('Password was successfully updated');
        handleResetPasswordForm();
      },
    });
  };

  const handleGoogleCalendarIntegrationUpdate = (payload: FormGoogleCalendarIntegrationValues): void => {
    updateGoogleIntegrationMutation.mutate(payload, {
      onError: error => {
        errorNotification('Integration update failed', error.response?.data.message);
      },
      onSuccess: () => {
        successNotification('Integration was successfully updated');
      },
    });
  };

  const handleGoogleCalendarIntegrationCreate = (authorizationCode: string): void => {
    createGoogleIntegrationMutation.mutate(
      { authorizationCode: authorizationCode },
      {
        onError: error => {
          errorNotification('Integration update failed', error.response?.data.message);
        },
        onSuccess: () => {
          successNotification('Integration was successfully updated');
        },
      },
    );
  };

  const handleGoogleLoginSuccess = (authorizationCode: string): void => {
    if (authorizationCode) {
      if (!!googleCalendarIntegration) {
        handleGoogleCalendarIntegrationUpdate({
          ...googleCalendarIntegrationForm.values,
          authorizationCode: authorizationCode,
        });
      } else {
        handleGoogleCalendarIntegrationCreate(authorizationCode);
      }
    }
  };

  const login = useGoogleLogin({
    onSuccess: codeResponse => handleGoogleLoginSuccess(codeResponse.code),
    flow: 'auth-code',
    scope: 'https://www.googleapis.com/auth/calendar.events',
  });

  const GoogleCalendarIntegrationContent = (
    <Stack>
      {!!googleCalendarIntegration && (
        <Stack>
          <Checkbox
            checked={googleCalendarIntegrationForm.values.syncAppointmentsToCalendar}
            label='Synchronize Appointments to google calendar'
            disabled={updateGoogleIntegrationMutation.isLoading}
            {...googleCalendarIntegrationForm.getInputProps('syncAppointmentsToCalendar')}
          />
          <Checkbox
            checked={googleCalendarIntegrationForm.values.syncClassesToCalendar}
            label='Synchronize Classes to google calendar'
            disabled={updateGoogleIntegrationMutation.isLoading}
            {...googleCalendarIntegrationForm.getInputProps('syncClassesToCalendar')}
          />
          <Button
            form='google-calendar-integration-form'
            type='submit'
            loading={updateGoogleIntegrationMutation.isLoading}
          >
            Save
          </Button>
        </Stack>
      )}
      {!googleCalendarIntegration && (
        <Button onClick={login}>
          <IconBrandGoogle style={{ paddingRight: `calc(${px(theme.spacing.xs)}px)` }} />
          Connect Google Calendar
        </Button>
      )}
    </Stack>
  );

  useShallowEffect(() => {
    usernameForm.setValues(user);
  }, [user]);

  useShallowEffect(() => {
    if (!!googleCalendarIntegration && !googleCalendarIntegrationsLoading) {
      googleCalendarIntegrationForm.setValues(googleCalendarIntegration);
    }
  }, [googleCalendarIntegration, googleCalendarIntegrationsLoading]);

  return (
    <Drawer
      opened={opened}
      onClose={handleCloseAndReset}
      size={600}
      title={title}
      closeOnClickOutside
      footer={
        <Group position='right'>
          <Button variant='subtle' onClick={handleCloseAndReset}>
            Close
          </Button>
        </Group>
      }
    >
      <Group mb='md' position='center'>
        <Avatar
          imgSrc={imgUrl || user.profilePicture}
          handleFileChange={files => handleProfileUpload(files[0])}
          loading={uploadMutation.isLoading}
        />
      </Group>
      {!isGymStaff && (
        <form id='account-details-form' onSubmit={usernameForm.onSubmit(handleAccountDetailsSubmit)}>
          <Stack>
            <TextInput
              type='email'
              label='Email'
              placeholder='Enter Email'
              required
              {...usernameForm.getInputProps('email')}
              disabled={!editableAccountDetails}
            />
            <Flex gap='sm' align='flex-end'>
              <TextInput
                label='First Name'
                placeholder='Enter First Name'
                required
                {...usernameForm.getInputProps('firstName')}
                disabled={!editableAccountDetails}
                sx={{ flexGrow: 1 }}
              />
              <TextInput
                label='Last Name'
                placeholder='Enter Last Name'
                required
                {...usernameForm.getInputProps('lastName')}
                disabled={!editableAccountDetails}
                sx={{ flexGrow: 1 }}
              />
              <Group noWrap sx={{ flexBasis: '30%' }}>
                {editableAccountDetails ? (
                  <>
                    <Button variant='subtle' color='red' onClick={handleResetUsernameForm}>
                      Cancel
                    </Button>
                    <Button color='green' form='account-details-form' type='submit'>
                      Save
                    </Button>
                  </>
                ) : (
                  <Button fullWidth onClick={() => setEditableAccountDetails(true)}>
                    Edit
                  </Button>
                )}
              </Group>
            </Flex>
          </Stack>
        </form>
      )}
      {isGymStaff && (
        <StaffAccountGeneralDetails
          isEditableAccountDetails={editableAccountDetails}
          setIsEditableAccountDetails={setEditableAccountDetails}
        />
      )}

      <Divider mx='-xl' mt='xl' mb='md' />

      {editablePassword ? (
        <form id='password-form' onSubmit={passwordForm.onSubmit(handlePasswordSubmit)}>
          <Flex gap='sm' align='flex-start'>
            <Stack sx={{ flexGrow: 1 }}>
              <PasswordInput
                label='Current Password'
                placeholder='Enter Current Password'
                required
                {...passwordForm.getInputProps('currentPassword')}
                disabled={!editablePassword}
              />
              <PasswordInput
                label='New Password'
                placeholder='Enter New Password'
                required
                {...passwordForm.getInputProps('newPassword')}
                disabled={!editablePassword}
              />
              <PasswordInput
                label='Re-enter New Password'
                placeholder='Re-enter New Password'
                required
                {...passwordForm.getInputProps('reNewPassword')}
                disabled={!editablePassword}
              />
            </Stack>
            <Group noWrap mt='xl' sx={{ flexBasis: '30%' }}>
              <Button variant='subtle' color='red' onClick={handleResetPasswordForm}>
                Cancel
              </Button>
              <Button color='green' form='password-form' type='submit'>
                Save
              </Button>
            </Group>
          </Flex>
        </form>
      ) : (
        <Flex gap='sm' align='flex-end'>
          <TextInput disabled label='Password' value='*******' sx={{ flexGrow: 1 }} />
          <Group noWrap sx={{ flexBasis: '30%' }}>
            <Button fullWidth onClick={() => setEditablePassword(true)}>
              Change Password
            </Button>
          </Group>
        </Flex>
      )}
      <Divider mx='-xl' my='lg' />
      {user.isCoach && (
        <form
          id='google-calendar-integration-form'
          onSubmit={googleCalendarIntegrationForm.onSubmit(handleGoogleCalendarIntegrationUpdate)}
        >
          <ExpandableSection
            title='Integrations'
            opened={integrationsOpened}
            handleToggle={handleIntegrationsToggle}
          >
            <InfoCard
              sx={{
                marginTop: theme.spacing.sm,
              }}
              header={
                <Group>
                  <Title order={4}>Integrate your calendar to Google Calendar</Title>
                  {googleCalendarIntegration?.email && (
                    <Tooltip label='Click here to change google calendar email'>
                      <Text
                        onClick={login}
                        size='sm'
                        color='dimmed'
                        sx={{
                          '&:hover': {
                            cursor: 'pointer',
                            textDecoration: 'underline',
                          },
                        }}
                      >
                        Integrated with the email {googleCalendarIntegration.email}
                      </Text>
                    </Tooltip>
                  )}
                </Group>
              }
              actionButton={<></>}
              content={<InfoCardSection content={GoogleCalendarIntegrationContent} />}
            />
          </ExpandableSection>
        </form>
      )}
    </Drawer>
  );
};

export default DrawerAccountDetails;
