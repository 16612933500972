import React from 'react';

import PublicLayout from 'layouts/PublicLayout';
import { Navigate } from 'react-router-dom';

import { IRouteMap } from './types';

export const unauthenticatedRouteMap: IRouteMap = {
  layout: PublicLayout,
  routes: [
    {
      key: 'login',
      component: React.lazy(() => import('pages/public/Login')),
      path: '/login',
    },
    {
      key: 'activate',
      component: React.lazy(() => import('pages/public/Activate')),
      path: '/activate/:uid/:token',
    },
    {
      key: 'reset-password',
      component: React.lazy(() => import('pages/public/PasswordReset')),
      path: '/reset-password',
    },
    {
      key: 'reset-password-confirm',
      component: React.lazy(() => import('pages/public/PasswordResetConfirm')),
      path: '/password/reset/confirm/:uid/:token',
    },
    {
      key: 'social-login-error',
      component: React.lazy(() => import('pages/public/SocialLoginError')),
      path: '/social-login-error',
    },
    {
      key: 'redirect',
      component: () => Navigate({ to: '/login' }),
      path: '*',
    },
  ],
  navLinks: [],
};

export const getUnauthenticatedRouteMap = (): IRouteMap => {
  return unauthenticatedRouteMap;
};
