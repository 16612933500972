import { useClient } from 'context/auth-context';
import { useQuery, UseQueryResult } from 'react-query';
import { AthleteLinkedProfiles } from 'types';

export default function useAthleteLinkedProfiles(
  userId: string,
): UseQueryResult<{ data: AthleteLinkedProfiles[] }> {
  const client = useClient();

  return useQuery(['linkedProfiles', userId], () => client.get(`athletes/${userId}/linked-profiles/`), {
    enabled: !!userId,
  });
}
