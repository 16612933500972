import { AxiosResponse } from 'axios';
import { useClient } from 'context/auth-context';
import { useQuery, UseQueryResult } from 'react-query';
import { UrlParams, AthleteAutoComplete } from 'types';
import { encodeUrlParams } from 'utils/functions';

interface IUseAutoCompleteAthleteProps {
  search?: string;
}

function useAutoCompleteAthlete({
  search,
}: IUseAutoCompleteAthleteProps): UseQueryResult<{ data: Array<AthleteAutoComplete> }> {
  const client = useClient();
  const fetchAutoCompleteAthlete = (): Promise<AxiosResponse<{ data: Array<AthleteAutoComplete> }>> => {
    const params: UrlParams = { limit: '100', offset: '0' };

    if (search) {
      params.search = search;
    }

    return client.get(`athletes/auto_complete/?${encodeUrlParams(params)}`);
  };

  return useQuery(['athlete', search], fetchAutoCompleteAthlete);
}

export default useAutoCompleteAthlete;
