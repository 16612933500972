import 'theme.mobiscroll.scss';

import React from 'react';

import { MantineProvider } from '@mantine/core';

import { lightTheme } from '../theme';

const ThemeProvider = ({ children }: { children: JSX.Element }): JSX.Element => {
  const root = document.documentElement;
  root.style.setProperty('font-size', '14px');

  return (
    <MantineProvider theme={lightTheme} withNormalizeCSS withGlobalStyles>
      {children}
    </MantineProvider>
  );
};

export default ThemeProvider;
