import { showNotification } from '@mantine/notifications';

export const notificationHandler = (): {
  successNotification: (successTitle: string, successMessage?: string) => void;
  errorNotification: (errorTitle: string, errorMessage?: string) => void;
  infoNotification: (infoTitle: string, infoMessage?: string) => void;
} => {
  const successNotification = (successTitle: string, successMessage?: string): void => {
    showNotification({
      title: successTitle,
      message: successMessage || '',
      color: 'teal',
      autoClose: 5000,
      closeButtonProps: {
        'data-cy': 'notificationCloseBtn',
      },
    });
  };

  const errorNotification = (errorTitle: string, errorMessage?: string): void => {
    showNotification({
      title: errorTitle,
      message:
        errorMessage ||
        `Something went wrong, please try again. Contact Support ref: ${Math.floor(Date.now() / 1000)}.`,
      color: 'red',
      autoClose: 5000,
      closeButtonProps: {
        'data-cy': 'notificationCloseBtn',
      },
    });
  };

  const infoNotification = (infoTitle: string, infoMessage?: string): void => {
    showNotification({
      title: infoTitle,
      message: infoMessage || '',
      color: 'yellow',
      autoClose: 5000,
      closeButtonProps: {
        'data-cy': 'notificationCloseBtn',
      },
    });
  };
  return { successNotification, errorNotification, infoNotification };
};

export default notificationHandler;
