import React from 'react';

import SalesPortalLayout from 'layouts/SalesPortalLayout';

import { IRouteMap } from './types';

export const salesPortalRouteMap: IRouteMap = {
  layout: SalesPortalLayout,
  routes: [
    {
      key: 'sales-portal-home-page',
      component: React.lazy(() => import('pages/shared/SalesPortalHomePage')),
      path: 'sales-portal/:salesPortalSlug',
    },
    {
      key: 'sales-portal-package',
      component: React.lazy(() => import('pages/shared/SalesPortalPackage')),
      path: 'sales-portal/:salesPortalSlug/:packageId',
    },
    {
      key: 'sales-portal-billing-plan',
      component: React.lazy(() => import('pages/shared/SalesPortalBillingPlan')),
      path: 'sales-portal/:salesPortalSlug/:packageId/:billingPlanId',
    },
  ],
  navLinks: [],
};

export const getSalesPortalRouteMap = (): IRouteMap => {
  return salesPortalRouteMap;
};
