import { useCallback } from 'react';

import { AxiosError, AxiosResponse } from 'axios';
import { useClient } from 'context/auth-context';
import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { ErrorMessage, User } from 'types';

export default function useUpdateUser(): UseMutationResult<
  AxiosResponse<User>,
  AxiosError<ErrorMessage>,
  User
> {
  const client = useClient();
  const queryClient = useQueryClient();

  const updateUser = useCallback(
    (user: User): Promise<AxiosResponse<User>> => {
      return client.put(`auth/users/me/`, user);
    },
    [client],
  );

  return useMutation(updateUser, {
    onSuccess: () => {
      queryClient.invalidateQueries('user');
      queryClient.invalidateQueries('me');
    },
  });
}
