import { Ability, subject } from '@casl/ability';
import { Conditions, PossibleAbilities } from 'Can';
import { UseQueryResult } from 'react-query';
import { loadingMobileRouteMap, ownerMobileRouteMap } from 'routes/owner';
import { GymRetrieve, User } from 'types';

import { loadingRouteMap, onboardingRouteMap, ownerRouteMap } from './owner';
import { INavLink, IRouteMap } from './types';

const routeKeyToPermissionMap: { [key: string]: string | null } = {
  dashboard: null,
  arm: 'BusinessMetricsReportsView',
  'total-revenue': 'AccountingReportsView',
  'upcoming-revenue': 'AccountingReportsView',
  'pending-payments': 'PaymentReportsView',
  'past-due-payments': 'PaymentReportsView',
  'total-members': 'AthleteReportsView',
  'new-members': 'AthleteReportsView',
  'cancelled-members': 'AthleteReportsView',
  'missing-members': 'AthleteReportsView',
  'reactivated-members': 'AthleteReportsView',
  'trial-members': 'AthleteReportsView',
  'athletes-on-hold': 'AthleteReportsView',
  'cancelled-trial-members': 'AthleteReportsView',
  'athletes-at-risk': 'AthleteReportsView',
  'paying-members': 'AthleteReportsView',
  'athlete-attendance': null,
  'athlete-birthdays': null,
  'package-revenue': 'PackageReportsView',
  'package-category-revenue': 'PackageReportsView',
  kiosk: 'Kiosk',
  scheduling: null,
  programming: null,
  packages: null,
  retail: null,
  'fees-and-misc': null,
  'tax-rates': null,
  'sales-portal': null,
  athletes: 'AthleteView',
  'athlete-detail': 'AthleteView',
  staff: 'StaffView',
  classes: null,
  appointments: null,
  location: null,
  'issued-documents': null,
  'document-templates': null,
  invoices: 'InvoiceReportsView',
  'payroll-report': 'PayrollReportView',
  'pay-rates': 'PayrollMutate',
  'assign-rates': 'PayrollMutate',
  'log-work': null,
  flows: 'FlowView',
  'flow-builder': 'FlowView',
  'flow-builder-edit': 'FlowView',
  'flow-templates': 'FlowView',
  'gym-settings': 'GymMutate',
  redirect: null,
};

const checkPermission = (
  key: string,
  ability: Ability<PossibleAbilities, Conditions>,
  user: User,
): boolean => {
  const permission = key in routeKeyToPermissionMap ? routeKeyToPermissionMap[key] : null;
  if (!permission) return true;
  const allowed = ability.can(
    'read',
    subject(permission, {
      isGymStaff: user.isGymStaff,
      isKiloStaff: user.isKiloStaff,
      isOwner: user.isOwner,
      permissions: user.permissions,
    }),
  );
  return allowed;
};

const removeRoutesAndLinks = (
  routeMap: IRouteMap,
  user: User | undefined,
  ability: Ability<PossibleAbilities, Conditions> | undefined,
): IRouteMap | null => {
  if (!user || !ability) return null;
  const trimmedRoutes = routeMap.routes.filter(route => {
    return checkPermission(route.key, ability, user);
  });

  const trimmedNavLinks = routeMap.navLinks.reduce((accum, navLink) => {
    if (navLink.subLinks) {
      const subLinks = navLink.subLinks.reduce((acc, subLink) => {
        const allowed = checkPermission(subLink.key, ability, user);
        if (allowed) acc.push(subLink);
        return acc;
      }, [] as INavLink[]);
      if (subLinks.length) accum.push({ ...navLink, subLinks });
    } else {
      const allowed = checkPermission(navLink.key, ability, user);
      if (allowed) accum.push(navLink);
    }
    return accum;
  }, [] as INavLink[]);

  return {
    layout: routeMap.layout,
    routes: trimmedRoutes,
    navLinks: trimmedNavLinks,
  };
};

export const getGymStaffRouteMap = (
  user: User | undefined,
  ability: Ability<PossibleAbilities, Conditions> | undefined,
  gymHook:
    | UseQueryResult<{
        data: GymRetrieve;
      }>
    | undefined,
  isDesktop: boolean,
): IRouteMap => {
  if (gymHook?.isSuccess && gymHook?.data.data.stripeStatus) {
    if (gymHook.data.data.stripeStatus === 'Onboard') {
      return onboardingRouteMap;
    }

    const routeMap = isDesktop ? ownerRouteMap : ownerMobileRouteMap;
    const filteredRouteMap = removeRoutesAndLinks(routeMap, user, ability);
    if (filteredRouteMap) {
      return filteredRouteMap;
    }
  }
  return isDesktop ? loadingRouteMap : loadingMobileRouteMap;
};
