// https://www.vidbeo.com/blog/reload-nuxt-spa-on-missing-chunk-error/

export const errorReload = (error: string, retries = 1): void => {
  console.error(error);

  let errRetries: string | null = sessionStorage.getItem('errRetries');
  console.log('errorReload()', typeof errRetries, errRetries);
  if (typeof errRetries === 'string') {
    // we have tried before
    if (parseInt(errRetries) >= retries) {
      // ah, give up
      console.log('errorReload() now give up');
      return;
    }

    console.log('errorReload() now try again');
    sessionStorage.setItem('errRetries', String(parseInt(errRetries) + 1));
  } else {
    // we have not tried before
    console.log('errorReload() try again');
    sessionStorage.setItem('errRetries', '1');
  }

  window.location.reload();
};
