import React from 'react';

import { Select, SelectProps } from '@mantine/core';

export const PROVINCES_TERRITORIES: Array<{ label: string; value: string }> = [
  { value: 'AB', label: 'Alberta' },
  { value: 'BC', label: 'British Columbia' },
  { value: 'MB', label: 'Manitoba' },
  { value: 'NB', label: 'New Brunswick' },
  { value: 'NL', label: 'Newfoundland and Labrador' },
  { value: 'NS', label: 'Nova Scotia' },
  { value: 'NT', label: 'Northwest Territories' },
  { value: 'NU', label: 'Nunavut' },
  { value: 'ON', label: 'Ontario' },
  { value: 'PE', label: 'Prince Edward Island' },
  { value: 'QC', label: 'Quebec' },
  { value: 'SK', label: 'Saskatchewan' },
  { value: 'YT', label: 'Yukon' },
];

const CanadaProvinceTerritorySelect = ({ ...acProps }: Omit<SelectProps, 'data'>): JSX.Element => {
  return (
    <Select
      label='Province/Territory'
      data={PROVINCES_TERRITORIES}
      searchable
      placeholder='Select province or territory'
      filter={(value, item: { label: string; value: string }) =>
        item.label.toLowerCase().includes(value.toLowerCase().trim()) ||
        item.value.toLowerCase().includes(value.toLowerCase().trim())
      }
      {...acProps}
    />
  );
};

export default CanadaProvinceTerritorySelect;
