import React from 'react';

import {
  MediaQuery,
  ScrollArea,
  Stack,
  Navbar as MantineNavbar,
  Divider,
  Group,
  useMantineTheme,
} from '@mantine/core';
import { ReactComponent as KiloLogo } from 'assets/svg/long_kilo_blue_white.svg';
import { Link } from 'react-router-dom';
import { INavLink } from 'routes/types';

import NavLink from './NavLink';
import UserButton from './UserButton';

interface INavBarProps {
  opened: boolean;
  links: INavLink[];
}

const Navbar = ({ opened, links }: INavBarProps): JSX.Element => {
  const theme = useMantineTheme();

  return (
    <MantineNavbar
      hiddenBreakpoint='sm'
      hidden={!opened}
      width={{ sm: 170, md: 208 }}
      styles={() => ({
        root: {
          boxShadow: theme.shadows.md,
        },
      })}
      data-cy='navbar'
    >
      <MediaQuery smallerThan='sm' styles={{ display: 'none' }}>
        <MantineNavbar.Section>
          <Group py='sm' px='md'>
            <Link to='/'>
              <KiloLogo height='1.5rem' width='auto' />
            </Link>
          </Group>
          <Divider />
        </MantineNavbar.Section>
      </MediaQuery>

      <MantineNavbar.Section component={ScrollArea} grow>
        <Stack spacing={0}>
          {links.map(link => (
            <NavLink key={link.key} label={link.label} route={link.route} subLinks={link.subLinks} />
          ))}
        </Stack>
      </MantineNavbar.Section>

      <MantineNavbar.Section>
        <Divider />
        <UserButton />
      </MantineNavbar.Section>
    </MantineNavbar>
  );
};

export default Navbar;
